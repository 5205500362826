<template>
  <navigation index="repairManage" ref="navigations">
    <div style="height: 100%; width: calc(100% - 0px)">
      <div
        style="
          background-color: #01a1ff;
          border-radius: 1px;
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: calc(100% - 10px);
        "
      >
        <el-button
          style="margin-left: 10px"
          size="mini"
          @click="
            () => {
              AddNewMobileInfoData();
            }
          "
        >
          <el-icon size="14pt" style="padding: 5px">
            <CirclePlusFilled />
          </el-icon>

          {{ tools.GetLanguageValue("web.添加") }}</el-button
        >

        <span></span>
        <div
          v-if="!repairForm.IsCreate"
          style="
            display: flex;
            justify-content: right;
            align-items: center;
            gap: 10px;
            margin-right: 10px;
          "
        >
          <el-button size="mini" @click="OpenSendEmail">
            <el-icon size="14pt" style="padding: 5px">
              <svg
                t="1727057474789"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="8860"
                width="48"
                height="48"
              >
                <path
                  d="M924.672 157.696h-825.856c-45.568 0-82.944 37.376-82.944 82.944v506.88c0 46.08 37.376 82.944 82.944 82.944H924.672c46.08 0 82.944-37.376 82.944-82.944v-506.368c0-46.08-36.864-83.456-82.944-83.456m-19.456 59.904l-329.216 291.84c-28.672 25.6-50.176 38.4-63.488 38.4s-34.816-12.8-63.488-38.4l-329.216-291.84H905.216m-827.392 510.976V271.36l257.024 225.792-257.024 231.424m43.52 41.984l258.048-229.376 42.496 37.376c24.576 21.504 54.784 32.256 90.624 32.256s66.048-11.264 90.624-32.256l42.496-37.376 258.048 229.376h-782.336m824.832-41.984L689.152 496.64l257.024-225.28v457.216m0 0"
                  fill="#8a8a8a"
                  p-id="8861"
                ></path>
              </svg>
            </el-icon>

            {{ tools.GetLanguageValue("web.发送邮件") }}</el-button
          >

          <el-button
            size="mini"
            @click="printRepairReserve"
            v-if="repairForm.IsRepairReserve"
          >
            <el-icon size="14pt" style="padding: 5px">
              <svg
                t="1710752251968"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="8695"
                width="200"
                height="200"
              >
                <path
                  d="M930.906839 231.021087V1024l-248.242274-186.181705-217.211989 186.181705-186.181706-186.181705-248.242274 186.181705V139.6369a44.590518 44.590518 0 0 1 2.079029-10.239994A108.326722 108.326722 0 0 1 139.634591 0.000621H868.84627a123.748773 123.748773 0 0 1 62.060569 231.020466zM248.240586 248.242894a123.097138 123.097138 0 0 1-81.299345-31.030284H186.180017a62.060568 62.060568 0 0 0 62.060569-62.060568V93.091473H124.119449v736.969251L279.27087 713.697158l186.181706 186.181705 217.211989-186.181705 155.151421 116.363566V248.242894H248.240586z m651.635969-155.151421H341.331439v62.060569h558.545116V93.091473z m-186.181706 310.302843a31.030284 31.030284 0 0 1-31.030284 31.030284H248.240586a31.030284 31.030284 0 0 1 0-62.060569h434.423979a31.030284 31.030284 0 0 1 31.030284 31.030285zM248.240586 527.515452h248.242274a31.030284 31.030284 0 0 1 0 62.060569H248.240586a31.030284 31.030284 0 0 1 0-62.060569z"
                  fill="#999999"
                  p-id="8696"
                ></path>
              </svg>
            </el-icon>

            {{ tools.GetLanguageValue("web.打印预定") }}</el-button
          >

          <el-button
            size="mini"
            @click="printRepairReserveA4"
            v-if="repairForm.IsRepairReserve"
          >
            <el-icon size="14pt" style="padding: 5px">
              <svg
                t="1710752251968"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="8695"
                width="200"
                height="200"
              >
                <path
                  d="M930.906839 231.021087V1024l-248.242274-186.181705-217.211989 186.181705-186.181706-186.181705-248.242274 186.181705V139.6369a44.590518 44.590518 0 0 1 2.079029-10.239994A108.326722 108.326722 0 0 1 139.634591 0.000621H868.84627a123.748773 123.748773 0 0 1 62.060569 231.020466zM248.240586 248.242894a123.097138 123.097138 0 0 1-81.299345-31.030284H186.180017a62.060568 62.060568 0 0 0 62.060569-62.060568V93.091473H124.119449v736.969251L279.27087 713.697158l186.181706 186.181705 217.211989-186.181705 155.151421 116.363566V248.242894H248.240586z m651.635969-155.151421H341.331439v62.060569h558.545116V93.091473z m-186.181706 310.302843a31.030284 31.030284 0 0 1-31.030284 31.030284H248.240586a31.030284 31.030284 0 0 1 0-62.060569h434.423979a31.030284 31.030284 0 0 1 31.030284 31.030285zM248.240586 527.515452h248.242274a31.030284 31.030284 0 0 1 0 62.060569H248.240586a31.030284 31.030284 0 0 1 0-62.060569z"
                  fill="#999999"
                  p-id="8696"
                ></path>
              </svg>
            </el-icon>

            {{ tools.GetLanguageValue("web.打印预定") }}A4</el-button
          >

          <el-button size="mini" @click="printXiaoPiao">
            <el-icon size="14pt" style="padding: 5px">
              <svg
                t="1710752251968"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="8695"
                width="200"
                height="200"
              >
                <path
                  d="M930.906839 231.021087V1024l-248.242274-186.181705-217.211989 186.181705-186.181706-186.181705-248.242274 186.181705V139.6369a44.590518 44.590518 0 0 1 2.079029-10.239994A108.326722 108.326722 0 0 1 139.634591 0.000621H868.84627a123.748773 123.748773 0 0 1 62.060569 231.020466zM248.240586 248.242894a123.097138 123.097138 0 0 1-81.299345-31.030284H186.180017a62.060568 62.060568 0 0 0 62.060569-62.060568V93.091473H124.119449v736.969251L279.27087 713.697158l186.181706 186.181705 217.211989-186.181705 155.151421 116.363566V248.242894H248.240586z m651.635969-155.151421H341.331439v62.060569h558.545116V93.091473z m-186.181706 310.302843a31.030284 31.030284 0 0 1-31.030284 31.030284H248.240586a31.030284 31.030284 0 0 1 0-62.060569h434.423979a31.030284 31.030284 0 0 1 31.030284 31.030285zM248.240586 527.515452h248.242274a31.030284 31.030284 0 0 1 0 62.060569H248.240586a31.030284 31.030284 0 0 1 0-62.060569z"
                  fill="#999999"
                  p-id="8696"
                ></path>
              </svg>
            </el-icon>

            {{ tools.GetLanguageValue("web.打印小票") }}</el-button
          >
          <el-button size="mini" @click="printXiaoPiaoAfour">
            <el-icon
              class="menu_item_icon"
              color="#000"
              size="14pt"
              style="padding: 5px"
            >
              <svg
                t="1710751773031"
                class="icon"
                viewBox="0 0 1194 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="5142"
                width="200"
                height="200"
              >
                <path
                  d="M66.355605 959.99104h1061.946355V639.994027H66.355605v319.997013z m112.024554-639.994027l-107.860327 255.997611h1053.6179l-107.860327-255.997611h-87.07332v127.998806h49.765935c11.059097 0 16.588645 5.32475 16.588645 16.008384v31.982634c0 10.683634-5.529548 16.008384-16.588645 16.008384H215.687544c-11.024964 0-16.588645-5.32475-16.588645-16.008384v-31.982634c0-10.683634 5.563681-16.008384 16.588645-16.008384h49.834202v-127.998806H178.346026z m153.496167 127.998806h530.939045V63.999403H331.876326v383.996416z m852.437911 105.983011c6.894869 14.677196 10.342303 30.037053 10.342304 45.97717v360.03504c0 11.946555-3.106104 22.630189-9.318313 31.982635-6.143943 9.215914-14.2676 17.10064-23.858978 23.005652-9.898574 6.075677-21.435533 9.215914-33.17729 9.011116H66.355605c-17.953966 0-33.484487-6.348741-46.625698-19.012089A60.483702 60.483702 0 0 1 0.001024 959.99104V599.956c0-15.974251 3.447434-31.982635 10.376436-47.991018l105.812346-255.997611c11.024964-26.623752 31.743704-39.935627 62.190353-39.935627h87.107454V63.96527c0-17.339571 6.587672-32.289832 19.694749-44.987314 12.185486-12.287885 29.081329-19.114488 46.693964-19.012089h530.939045c17.988099 0 33.51862 6.348741 46.659831 19.012089 13.141211 12.629215 19.728883 27.647742 19.728883 44.987314V255.997611h87.107453c30.412516 0 51.131256 13.311876 62.224486 40.003893l105.812346 257.977326z"
                  fill="#8a8a8a"
                  p-id="5143"
                ></path>
              </svg>
            </el-icon>

            {{ tools.GetLanguageValue("web.打印小票A4") }}
          </el-button>
          <el-button
            size="mini"
            @click="UpdatePrintInfoExamine"
            style="margin-right: 15px"
          >
            {{ tools.GetLanguageValue("web.打印保修") }}</el-button
          >

          <el-popover
            placement="top-start"
            :title="tools.GetLanguageValue('web.快捷键')"
            :width="300"
            trigger="hover"
          >
            <template #reference>
              <el-icon
                class="el-icon--right"
                color="#fff"
                style="font-size: 18pt; margin-right: 5px"
              >
                <InfoFilled />
              </el-icon>
            </template>

            <P>alt + Q {{ tools.GetLanguageValue("web.打印小票") }}</P>
            <P>alt + W {{ tools.GetLanguageValue("web.打印小票A4") }}</P>
            <P>alt + E {{ tools.GetLanguageValue("web.打印保修") }}</P>
            <P>alt + C {{ tools.GetLanguageValue("web.新增维修") }}</P>
            <P>alt + D {{ tools.GetLanguageValue("web.添加新客户") }}</P>
            <P>alt + S {{ tools.GetLanguageValue("web.保存表单") }}</P>
            <P>
              {{ tools.GetLanguageValue("web.品牌和属性红色的为临时数据") }}</P
            >
          </el-popover>
        </div>
      </div>

      <el-scrollbar
        style="
          margin-top: 10px;
          width: calc(100% - 1px);
          height: calc(100% - 80px);
        "
      >
        <el-card
          style="width: calc(100% - 15px); margin: 0 auto; margin-top: 10px"
        >
          <el-collapse v-model="UserCollapseOpt">
            <el-collapse-item name="1">
              <template #title>
                <div style="display: flex; align-items: center">
                  <el-icon size="18pt">
                    <Avatar />
                  </el-icon>
                  <span style="font-size: 14pt; margin-left: 10px">
                    <span v-if="userForm.Name != null && userForm.Name != ''">
                      {{ userForm.Name }} /
                    </span>
                    <span
                      v-if="
                        userForm.DocNumber != null && userForm.DocNumber != ''
                      "
                    >
                      {{ userForm.DocNumber }} /
                    </span>

                    <span
                      v-if="
                        userForm.PhoneNumber != null &&
                        userForm.PhoneNumber != ''
                      "
                    >
                      {{ userForm.PhoneNumber }} /
                    </span>

                    <span v-if="userForm.Email != null && userForm.Email != ''">
                      {{ userForm.Email }} /
                    </span>
                    <span
                      v-if="userForm.Address != null && userForm.Address != ''"
                    >
                      {{ userForm.Address }}
                    </span>
                  </span>
                </div>
              </template>
              <div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    height: 35px;
                  "
                >
                  <el-input
                    ref="selectInputRef"
                    @input="selectInputChange"
                    v-model="selectInput"
                    v-if="selectInput.indexOf('/') != -1"
                    style="width: 350px; margin-left: 3px"
                    @keydown.enter="UserSeletValueEnter"
                    @keydown.tab="UserSeletValueEnter"
                  >
                    <template #prefix>
                      <el-icon>
                        <Search />
                      </el-icon>
                    </template>
                  </el-input>

                  <el-select
                    v-if="selectInput.indexOf('/') == -1"
                    v-model="userSeletvalue"
                    filterable
                    remote
                    reserve-keyword
                    :placeholder="tools.GetLanguageValue('web.请输入客户信息')"
                    :remote-method="remoteMethod"
                    :loading="userListloading"
                    @change="userSelected"
                    style="width: 350px; margin-left: 3px"
                  >
                    <el-option
                      style="width: 400px"
                      v-for="item in userListoptions"
                      :key="item.Id"
                      :label="item.Name"
                      :value="item"
                    >
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        <div
                          style="width: 160px; padding: 0px 5px"
                          class="fonthidden"
                        >
                          {{ item.Name }}
                        </div>
                        <div style="width: 100px" class="fonthidden">
                          {{ item.PhoneNumber }}
                        </div>
                        <div
                          style="width: 100px; margin-left: 10px"
                          class="fonthidden"
                        >
                          {{ item.DocNumber }}
                        </div>
                      </div>
                      <span> {{ item.Name + item.DocNumber }}</span>
                    </el-option>

                    <template #prefix>
                      <el-icon>
                        <Search />
                      </el-icon>
                    </template>
                  </el-select>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="tools.GetLanguageValue('web.添加新客户')"
                    placement="top-start"
                  >
                    <el-icon
                      :size="22"
                      color="#000"
                      @click.stop="clearUserForm"
                    >
                      <Delete />
                    </el-icon>
                  </el-tooltip>
                </div>

                <!-- //输入客户信息 -->
                <el-form :model="userForm">
                  <div style="display: flex; margin: 3px">
                    <div
                      style="
                        display: flex;
                        width: calc(100% - 120px);
                        flex-wrap: wrap;
                        gap: 2px 5px;
                      "
                    >
                      <el-input
                        class="userInfoInput"
                        :placeholder="tools.GetLanguageValue('web.姓名')"
                        v-model="userForm.Name"
                      >
                        <template #prefix>
                          <el-icon>
                            <Avatar />
                          </el-icon>
                        </template>
                      </el-input>
                      <el-input
                        class="userInfoInput"
                        :placeholder="tools.GetLanguageValue('web.电话')"
                        v-model="userForm.PhoneNumber"
                      >
                        <template #prefix>
                          <el-icon>
                            <PhoneFilled />
                          </el-icon>
                        </template>
                      </el-input>

                      <el-input
                        :placeholder="tools.GetLanguageValue('web.证件号')"
                        class="userInfoInput"
                        v-model="userForm.DocNumber"
                      >
                        <template #prefix>
                          <el-icon>
                            <Postcard />
                          </el-icon>
                        </template>
                      </el-input>

                      <el-input
                        :placeholder="tools.GetLanguageValue('web.地址')"
                        class="userInfoInput"
                        v-model="userForm.Address"
                      >
                        <template #prefix>
                          <el-icon>
                            <Location />
                          </el-icon>
                        </template>
                      </el-input>

                      <el-select
                        v-model="userForm.Level"
                        class="userInfoInput"
                        style="width: 23%"
                      >
                        <template #prefix>
                          <el-icon>
                            <Star />
                          </el-icon>
                        </template>
                        <el-option
                          v-for="item in clientLevelOptions"
                          :key="item.Value"
                          :label="item.Value"
                          :value="item.Key"
                        ></el-option>
                      </el-select>

                      <!-- <el-select
                        v-model="userForm.DocType"
                        class="userInfoInput"
                        style="width: 23%"
                        :placeholder="
                          tools.GetLanguageValue('web.请选择证件类型')
                        "
                      >
                        <template #prefix>
                          <el-icon><Management /></el-icon>
                        </template>
                        <el-option
                          v-for="item in docTypeOptions"
                          :key="item.Key"
                          :label="item.Value"
                          :value="item.Key"
                        ></el-option>
                      </el-select> -->

                      <el-input
                        class="userInfoInput"
                        :placeholder="tools.GetLanguageValue('web.电子邮件')"
                        v-model="userForm.Email"
                      >
                        <template #prefix>
                          <el-icon>
                            <Message />
                          </el-icon>
                        </template>
                      </el-input>
                    </div>
                    <div
                      style="
                        width: 120px;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                      "
                    >
                      <el-button
                        v-if="
                          userForm.Id ==
                            '00000000-0000-0000-0000-000000000000' ||
                          userForm.Id == '' ||
                          userForm.Id == undefined ||
                          userForm.Id == 'undefined'
                        "
                        type="danger"
                        @click="addClientWithRepairInfo('add')"
                      >
                        {{
                          tools.GetLanguageValue("web.保存为新客户")
                        }}</el-button
                      >
                      <el-button
                        v-else
                        type="primary"
                        @click="addClientWithRepairInfo('update')"
                      >
                        {{
                          tools.GetLanguageValue("web.客户信息更改")
                        }}</el-button
                      >
                    </div>
                  </div>
                </el-form>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-card>

        <el-card
          style="width: calc(100% - 15px); margin: 0 auto; margin-top: 10px"
          v-for="(item, index) in repairForm.RepairMobileInfos"
          :key="index"
        >
          <el-collapse :accordion="false" v-model="CollapseOpt">
            <el-collapse-item :name="item.Id">
              <template #title>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <span style="font-size: 14pt; margin-left: 10px">
                    {{ item.BrandAndModel }}
                  </span>
                  <el-icon
                    v-if="!item.IsReworking"
                    @click="deleteMobileInfoClick(item)"
                    style="font-size: 25pt"
                  >
                    <DeleteFilled />
                  </el-icon>
                </div>
              </template>

              <el-col :span="24">
                <div style="min-height: 350px" :id="item.Id">
                  <div style="display: flex; width: 100%">
                    <div style="width: 60%; padding: 0px 5px">
                      <el-divider
                        content-position="left"
                        v-if="!item.IsReworking"
                      >
                        {{ tools.GetLanguageValue("web.维修") }}</el-divider
                      >
                      <el-divider
                        content-position="left"
                        v-if="item.IsReworking"
                      >
                        <span style="font-size: 20pt; color: red">
                          {{ tools.GetLanguageValue("web.返修") }}</span
                        ></el-divider
                      >
                      <el-form :model="item" style="margin: 5px">
                        <div style="display: flex; margin: 5px 0px">
                          <el-select
                            v-model="item.Repairstatus"
                            size="default"
                            popper-class="eloption"
                            :popper-append-to-body="true"
                          >
                            <el-option
                              v-for="item in repairstatusOptions"
                              :key="item.Key"
                              :value="item.Key"
                              :label="item.Value"
                            >
                              {{ item.Value }}
                            </el-option>
                          </el-select>

                          <el-button
                            style="margin-left: 10px"
                            size="small"
                            type="primary"
                            @click.stop="changeStatus(item)"
                          >
                            <el-icon size="13pt">
                              <refresh />
                            </el-icon>
                          </el-button>
                        </div>
                        <div>
                          <div style="display: flex; gap: 8px">
                            <el-select
                              @keydown.tab="
                                (event) => BrandHandleEvent(event, item)
                              "
                              @blur="(event) => BrandHandleEvent(event, item)"
                              style="width: 50%"
                              v-model="item.BrandObj"
                              filterable
                              allow-create
                              default-first-option
                              popper-class="eloption"
                              :popper-append-to-body="true"
                              :placeholder="tools.GetLanguageValue('web.牌子')"
                              @change="(event) => BrandChange(event, item)"
                            >
                              <el-option
                                :label="tools.GetLanguageValue('web.请选择')"
                                value="00000000-0000-0000-0000-000000000000"
                              >
                              </el-option>
                              <el-option
                                v-for="item in brandOptions"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                              >
                                <span v-if="item.IsTemp" style="color: red">
                                  {{ item.Name }}
                                </span>
                                <span v-else>
                                  {{ item.Name }}
                                </span>
                              </el-option>
                              <template #prefix>
                                <el-icon>
                                  <Apple />
                                </el-icon>
                              </template>
                            </el-select>

                            <el-select
                              style="width: 50%"
                              v-model="item.ModelObj"
                              filterable
                              allow-create
                              default-first-option
                              :placeholder="tools.GetLanguageValue('web.型号')"
                              @keydown.tab="
                                (event) => ModelHandleEvent(event, item)
                              "
                              @blur="(event) => ModelHandleEvent(event, item)"
                            >
                              <el-option
                                :label="tools.GetLanguageValue('web.请选择')"
                                value="00000000-0000-0000-0000-000000000000"
                              >
                              </el-option>
                              <el-option
                                v-for="item in item.ProductModels"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                              >
                                <span v-if="item.IsTemp" style="color: red">
                                  {{ item.Name }}
                                </span>
                                <span v-else>
                                  {{ item.Name }}
                                </span>
                              </el-option>
                              <template #prefix>
                                <el-icon>
                                  <Cpu />
                                </el-icon>
                              </template>
                            </el-select>
                          </div>
                        </div>
                        <div
                          style="
                            margin: 5px 0px;
                            display: flex;
                            gap: 4;
                            justify-content: space-between;
                          "
                        >
                          <el-input
                            v-model="item.ImelNumber"
                            style="width: 250px"
                          >
                            <template #prefix>
                              <span style="margin: 0px 5px">IMEI </span>
                            </template>
                          </el-input>

                          <el-select
                            style="width: calc(100% - 300px)"
                            v-model="item.ModelProperties"
                            filterable
                            multiple
                            :placeholder="tools.GetLanguageValue('web.属性')"
                          >
                            <el-option-group
                              v-for="group in attributeOptions"
                              :key="group.Id"
                              :label="group.Name"
                            >
                              <el-option
                                v-for="item in group.ProductAttribute"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                              />
                            </el-option-group>
                          </el-select>

                          <el-button
                            @click="OpenAttributeGroupItemWindow"
                            style="width: 30px"
                            :icon="Plus"
                          ></el-button>
                        </div>
                        <div>
                          <el-tabs v-model="item.ActiveTab">
                            <el-tab-pane name="server">
                              <template #label>
                                <span class="custom-tabs-label">
                                  <el-icon>
                                    <calendar />
                                  </el-icon>
                                  <span>
                                    {{
                                      tools.GetLanguageValue(
                                        "web.维修问题/服务"
                                      )
                                    }}</span
                                  >
                                  <el-input
                                    v-model="searchInputServerAndQues"
                                    @input="searchInputServerAndQuesChange"
                                    :placeholder="
                                      tools.GetLanguageValue('web.查找')
                                    "
                                    :prefix-icon="Search"
                                    v-if="item.ActiveTab == 'server'"
                                    style="
                                      width: 140px;
                                      margin-left: 10px;
                                      height: 25px;
                                    "
                                  >
                                    <template #append>
                                      <el-button
                                        @click="
                                          OpenProductServiceWindow({
                                            Name: '',
                                            MnemonicCode: '',
                                            Price: 0,
                                            Code: '',
                                            IncludeTax: false,
                                            OldPrice: '',
                                            Sort: 0,
                                            Images: '',
                                            Description: '',
                                            Cost: 0,
                                            MnemonicCode: '',
                                            TaxRate: 10,
                                          })
                                        "
                                        :icon="Plus"
                                      />
                                    </template>
                                  </el-input>
                                </span>
                              </template>
                              <div
                                style="
                                  width: 100%;
                                  height: 161px;
                                  border: 1px solid #eee;
                                "
                                :style="{
                                  height: DraggingAreaHeigth + 'px',
                                }"
                                v-if="IsDraggingMode"
                                @mousemove="dragging($event)"
                              >
                                <el-button
                                  type="primary"
                                  @click="
                                    addRepairMobileInfosRepairItem(item, server)
                                  "
                                  style="margin: 3px 3px 0px 0px"
                                  :key="server.Id"
                                  size="small"
                                  v-for="server in serverQuestionOptions"
                                  :style="{
                                    position:
                                      server.X == 0 && server.Y == 0
                                        ? ''
                                        : 'absolute',
                                    left: `${server.X}px`,
                                    top: `${server.Y}px`,
                                  }"
                                  @mousedown="dragStart($event, server)"
                                  @mouseup="dragEnd($event, server)"
                                >
                                  {{ server.Name }}
                                </el-button>

                                <div
                                  style="
                                    position: absolute;
                                    bottom: 8px;
                                    left: 10px;
                                  "
                                >
                                  <el-tooltip
                                    content="放大窗口"
                                    placement="top"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Plus"
                                      circle
                                      @click="AddDraggingArea()"
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="缩小窗口"
                                    placement="top"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Minus"
                                      circle
                                      @click="MinusDraggingArea()"
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="重置排列"
                                    placement="top"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Refresh"
                                      circle
                                      @click="ResetCatDraggingMode(false)"
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="锁定编辑"
                                    placement="top"
                                    v-if="IsDraggingEdit"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Unlock"
                                      circle
                                      @click="DraggingEdit(false)"
                                      style="
                                        background-color: red;
                                        color: #fff;
                                        border: 0px;
                                      "
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="解锁编辑"
                                    placement="top"
                                    v-if="!IsDraggingEdit"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Lock"
                                      circle
                                      @click="DraggingEdit(true)"
                                      style="
                                        background-color: #01a1ff;
                                        color: #fff;
                                        border: 0px;
                                      "
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="切换至默认排列"
                                    placement="top"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Switch"
                                      circle
                                      @click="CatDraggingMode(false)"
                                    />
                                  </el-tooltip>
                                </div>

                                <el-button
                                  @click="InsertServerPosition"
                                  style="
                                    position: absolute;
                                    bottom: 8px;
                                    right: 1px;
                                  "
                                >
                                  {{ tools.GetLanguageValue("web.保存") }}
                                </el-button>
                              </div>

                              <div v-if="!IsDraggingMode">
                                <el-button
                                  type="primary"
                                  @click="
                                    addRepairMobileInfosRepairItem(item, server)
                                  "
                                  style="margin: 3px 3px 0px 0px"
                                  :key="server.Id"
                                  size="small"
                                  v-for="server in serverQuestionOptions"
                                >
                                  {{ server.Name }}
                                </el-button>

                                <el-tooltip
                                  content="切换至自由排列"
                                  placement="top"
                                >
                                  <el-button
                                    size="22"
                                    :icon="Switch"
                                    circle
                                    @click="CatDraggingMode(true)"
                                  />
                                </el-tooltip>
                              </div>
                            </el-tab-pane>
                            <el-tab-pane
                              :label="tools.GetLanguageValue('web.配件')"
                              name="fittings"
                            >
                              <template #label>
                                <span class="custom-tabs-label">
                                  <el-icon>
                                    <calendar />
                                  </el-icon>
                                  <span>{{
                                    tools.GetLanguageValue("web.配件")
                                  }}</span>
                                  <el-input
                                    v-model="searchInputAttachement"
                                    @input="searchInputAttachementChange"
                                    :placeholder="
                                      tools.GetLanguageValue('web.查找')
                                    "
                                    :prefix-icon="Search"
                                    style="
                                      width: 140px;
                                      margin-left: 10px;
                                      height: 25px;
                                    "
                                    v-if="item.ActiveTab == 'fittings'"
                                  >
                                    <template #append>
                                      <el-button
                                        @click="
                                          OpenAttachment({
                                            Price: 0,
                                            TaxRate: 10,
                                            Description: '',
                                          })
                                        "
                                        :icon="Plus"
                                        circle
                                      />
                                    </template>
                                  </el-input>
                                </span>
                              </template>
                              <div v-if="!IsCatAttachmentDraggingMode">
                                <el-button
                                  type="primary"
                                  @click="
                                    addRepairMobileInfosFittingsItem(
                                      item,
                                      accessories
                                    )
                                  "
                                  :key="accessories.Id"
                                  size="small"
                                  style="margin: 3px 3px 0px 0px"
                                  v-for="accessories in accessoriesOptions"
                                >
                                  {{ accessories.Name }}
                                </el-button>

                                <el-tooltip
                                  content="切换至自由排列"
                                  placement="top"
                                >
                                  <el-button
                                    size="22"
                                    :icon="Switch"
                                    circle
                                    @click="CatAttachmentDraggingMode(true)"
                                  />
                                </el-tooltip>
                              </div>

                              <div
                                v-if="IsCatAttachmentDraggingMode"
                                style="
                                  width: calc(100% - 5px);
                                  height: 161px;
                                  border: 1px solid #eee;
                                "
                                :style="{
                                  height: AttachmentDraggingAreaHeigth + 'px',
                                }"
                                @mousemove="AttachmentDragging($event)"
                              >
                                <el-button
                                  type="primary"
                                  @click="
                                    addRepairMobileInfosFittingsItem(
                                      item,
                                      accessories
                                    )
                                  "
                                  :key="accessories.Id"
                                  size="small"
                                  style="margin: 3px 3px 0px 0px"
                                  v-for="accessories in accessoriesOptions"
                                  :style="{
                                    position:
                                      accessories.X == 0 && accessories.Y == 0
                                        ? ''
                                        : 'absolute',
                                    left: `${accessories.X}px`,
                                    top: `${accessories.Y}px`,
                                  }"
                                  @mousedown="
                                    AttachmentDragStart($event, accessories)
                                  "
                                  @mouseup="
                                    AttachmentDragEnd($event, accessories)
                                  "
                                >
                                  {{ accessories.Name }}
                                </el-button>

                                <div
                                  style="
                                    position: absolute;
                                    bottom: 8px;
                                    left: 10px;
                                  "
                                >
                                  <el-tooltip
                                    content="放大窗口"
                                    placement="top"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Plus"
                                      circle
                                      @click="AddAttachmentDraggingArea()"
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="缩小窗口"
                                    placement="top"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Minus"
                                      circle
                                      @click="MinusAttachmentDraggingArea()"
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="重置排列"
                                    placement="top"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Refresh"
                                      circle
                                      @click="
                                        ResetAttachmentDraggingMode(false)
                                      "
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="锁定编辑"
                                    placement="top"
                                    v-if="IsAttachmentDraggingEdit"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Unlock"
                                      circle
                                      @click="AttachmentDraggingEdit(false)"
                                      style="
                                        background-color: red;
                                        color: #fff;
                                        border: 0px;
                                      "
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="解锁编辑"
                                    placement="top"
                                    v-if="!IsAttachmentDraggingEdit"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Lock"
                                      circle
                                      @click="AttachmentDraggingEdit(true)"
                                      style="
                                        background-color: #01a1ff;
                                        color: #fff;
                                        border: 0px;
                                      "
                                    />
                                  </el-tooltip>

                                  <el-tooltip
                                    content="切换至默认排列"
                                    placement="top"
                                  >
                                    <el-button
                                      size="22"
                                      :icon="Switch"
                                      circle
                                      @click="CatAttachmentDraggingMode(false)"
                                    />
                                  </el-tooltip>
                                </div>

                                <el-button
                                  @click="InsertAttachmentServerPosition"
                                  style="
                                    position: absolute;
                                    bottom: 8px;
                                    right: 15px;
                                  "
                                >
                                  {{ tools.GetLanguageValue("web.保存") }}
                                </el-button>
                              </div>
                            </el-tab-pane>
                          </el-tabs>
                        </div>

                        <div
                          style="
                            margin: 5px 0px;
                            margin-top: 10px;
                            display: flex;
                            gap: 3px 8px;
                            align-items: center;
                            flex-wrap: wrap;
                          "
                        >
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="
                              tools.GetLanguageValue('web.请选择密码类型')
                            "
                            placement="bottom-end"
                          >
                            <span>
                              <el-select
                                prefix="Lock"
                                style="width: 140px"
                                v-model="item.PasswordType"
                                :placeholder="
                                  tools.GetLanguageValue('web.请选择密码类型')
                                "
                              >
                                <template #prefix>
                                  <el-icon style="">
                                    <Lock />
                                  </el-icon>
                                </template>

                                <el-option
                                  v-for="item in passwordTypeOptions"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                >
                                </el-option>
                              </el-select>
                            </span>
                          </el-tooltip>

                          <div v-if="item.PasswordType == 1">
                            <el-input
                              style="min-width: 100px"
                              v-model="item.Password"
                              :placeholder="
                                tools.GetLanguageValue('web.输入解锁密码')
                              "
                            ></el-input>
                          </div>
                          <div v-if="item.PasswordType == 2">
                            <BoxGridPassword
                              :modelId="index"
                              v-model="item.Password"
                            >
                            </BoxGridPassword>
                          </div>
                        </div>
                        <div>
                          <el-input
                            type="textarea"
                            v-model="item.Comment"
                            autosize
                            :placeholder="
                              tools.GetLanguageValue('web.请填写备注')
                            "
                          >
                            <template #prefix>
                              <el-icon>
                                <Document />
                              </el-icon>
                            </template>
                          </el-input>
                        </div>
                        <div style="margin: 5px 0px">
                          <el-input
                            type="textarea"
                            v-model="item.PrivateComment"
                            autosize
                            :placeholder="
                              tools.GetLanguageValue('web.请填写备注.不打印')
                            "
                          >
                            <template #prefix>
                              <el-icon>
                                <Warning />
                              </el-icon>
                            </template>
                          </el-input>
                        </div>
                        <div style="display: flex; gap: 5px">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="tools.GetLanguageValue('web.维修时间')"
                            placement="bottom-end"
                          >
                            <span>
                              <el-date-picker
                                style="width: 185px"
                                v-model="item.RepairStartTime"
                                value-format="YYYY/MM/DD HH:mm:ss"
                                type="datetime"
                                :clear-icon="Close"
                                :prefix-icon="Refresh"
                                :placeholder="
                                  tools.GetLanguageValue('web.维修时间')
                                "
                              />
                            </span>
                          </el-tooltip>

                          <div>
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="tools.GetLanguageValue('web.保修时间')"
                              placement="bottom-end"
                            >
                              <span>
                                <el-date-picker
                                  style="width: 185px"
                                  v-model="item.WarrantyStartTime"
                                  :clear-icon="Close"
                                  :prefix-icon="CircleCheck"
                                  type="datetime"
                                  value-format="YYYY/MM/DD HH:mm:ss"
                                  :placeholder="
                                    tools.GetLanguageValue('web.保修时间')
                                  "
                                />
                              </span>
                            </el-tooltip>
                          </div>
                        </div>

                        <div>
                          <el-form-item
                            label="隐藏的前端手机Id"
                            prop="hiddenField"
                            v-if="false"
                          >
                            <el-input v-model="item.FrontMobileId"></el-input>
                          </el-form-item>
                        </div>
                      </el-form>
                    </div>
                    <div style="width: 50%; padding: 0px 5px">
                      <el-divider content-position="left">
                        {{ tools.GetLanguageValue("web.金额") }}</el-divider
                      >
                      <el-row style="width: 100%" gutter="10">
                        <el-col :span="12">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="tools.GetLanguageValue('web.预算')"
                            placement="top-start"
                          >
                            <el-input
                              v-model="item.Budget"
                              @input="
                                (v) => {
                                  item.Budget =
                                    v
                                      .replace(/[^\d.]/g, '')
                                      .replace(/^0(\d)/, '$1')
                                      .replace(/^\./, '0.')
                                      .match(/^\d*(\.?\d{0,4})/g)[0] || '';
                                }
                              "
                              :placeholder="
                                tools.GetLanguageValue('web.请输入预算')
                              "
                            >
                              <template #prefix>
                                <svg
                                  t="1703631359330"
                                  style="width: 18px; height: 18px"
                                  class="icon"
                                  viewBox="0 0 1024 1024"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  p-id="6903"
                                  width="200"
                                  height="200"
                                >
                                  <path
                                    d="M695.128195 314.811826a22.378321 22.378321 0 1 0 0-44.756642h-100.101427V182.511192a22.378321 22.378321 0 1 0-44.756642 0v87.543992h-70.52368V182.511192a22.378321 22.378321 0 1 0-44.756642 0v87.572764c-70.143249 0.597821-127.025744 57.815991-127.025744 128.096706v7.260167c0 68.880472 54.641466 125.2131 122.850588 127.994406 1.349093 0.326084 2.742943 0.527489 4.175156 0.59782v174.573283h-104.647423a22.378321 22.378321 0 1 0 0 44.756642h104.647423v87.543991c0 0.22698 0.028772 0.447566 0.035166 0.67135-149.52235-35.223477-261.158203-169.749156-261.158203-329.872436a22.378321 22.378321 0 1 0-44.756642 0c0 211.532678 172.095682 383.62836 383.62836 383.62836a22.378321 22.378321 0 1 0 0-44.756642c-11.630333 0-23.126396-0.591427-34.462615-1.739115a22.307989 22.307989 0 0 0 1.473773-7.934714v-87.543991h70.52368v87.543991a22.378321 22.378321 0 1 0 44.756642 0v-88.202553c68.03649-2.966726 122.479748-59.229022 122.479747-127.984815v-7.260167c0-68.755793-54.440061-125.018089-122.479747-127.984814V314.811826h100.09823z m-342.404296 90.625806v-7.260166c0-45.600624 36.805944-82.74544 82.269102-83.340065v173.943493c-45.463158-0.591427-82.269102-37.73944-82.269102-83.343262z m127.025744 303.165509v-174.518935h70.52368v174.518935h-70.52368z m193.000231-91.143705v7.260167c0 44.072505-34.376298 80.242265-77.723106 83.161038V534.298398c43.346808 2.915576 77.723106 39.088533 77.723106 83.161038z m-122.476551-128.64977h-70.52368V314.811826h70.52368v173.99784z"
                                    fill="#515151"
                                    p-id="6904"
                                  ></path>
                                  <path
                                    d="M512.738485 44.958047c-257.366676 0-466.747838 209.381162-466.747838 466.747838s209.381162 466.747838 466.747838 466.747838 466.747838-209.381162 466.747838-466.747838-209.381162-466.747838-466.747838-466.747838z m0 888.739034c-232.686585 0-421.991196-189.304611-421.991196-421.991196s189.304611-421.991196 421.991196-421.991196 421.991196 189.304611 421.991196 421.991196-189.304611 421.991196-421.991196 421.991196z"
                                    fill="#515151"
                                    p-id="6905"
                                  ></path>
                                  <path
                                    d="M153.476919 467.422385a22.384715 22.384715 0 0 0 26.083532-17.925036 336.889638 336.889638 0 0 1 22.065024-72.339521 22.378321 22.378321 0 0 0-41.073809-17.787568 381.742187 381.742187 0 0 0-24.999782 81.968593 22.378321 22.378321 0 0 0 17.925035 26.083532z"
                                    fill="#515151"
                                    p-id="6906"
                                  ></path>
                                </svg>
                              </template>
                            </el-input>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="12">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="tools.GetLanguageValue('web.定金')"
                            placement="top-start"
                          >
                            <el-input
                              v-model="item.Downpayment"
                              :placeholder="
                                tools.GetLanguageValue('web.请输入定金')
                              "
                              @input="
                                (v) => {
                                  item.Downpayment =
                                    v
                                      .replace(/[^\d.]/g, '')
                                      .replace(/^0(\d)/, '$1')
                                      .replace(/^\./, '0.')
                                      .match(/^\d*(\.?\d{0,4})/g)[0] || '';
                                }
                              "
                            >
                              <template #prefix>
                                <svg
                                  t="1703631430717"
                                  style="width: 18px; height: 18px"
                                  class="icon"
                                  viewBox="0 0 1024 1024"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  p-id="9961"
                                  width="200"
                                  height="200"
                                >
                                  <path
                                    d="M921.529187 352.519776C878.16454 242.165412 794.426371 155.310252 685.74409 107.945501c-108.682281-47.357588-229.316779-49.559742-339.671143-6.210445-98.722442 38.794561-178.632423 109.902062-228.293473 202.27182l0-153.383366c0-11.04659-8.952906-19.999496-19.999496-19.999496-11.04659 0-19.999496 8.952906-19.999496 19.999496l0 217.020732c0 11.04659 8.952906 19.999496 19.999496 19.999496l217.028918 0c11.04659 0 19.999496-8.952906 19.999496-19.999496 0-11.04659-8.952906-19.999496-19.999496-19.999496L140.806933 347.644745c43.630706-96.036263 121.485889-170.011068 219.890083-208.68488 100.401688-39.451524 210.177883-37.435611 309.063031 5.655813 98.893334 43.091424 175.085644 122.126478 214.544331 222.528166 39.451524 100.408851 37.443797 210.16151-5.655813 309.055868-43.091424 98.901521-122.126478 175.092807-222.528166 214.544331-100.408851 39.466873-210.169697 37.45096-309.063031-5.64865-72.050989-31.397081-132.869147-82.542948-175.866427-147.899468-6.077416-9.241478-18.491143-11.780301-27.701922-5.718235-9.226129 6.077416-11.788487 18.475793-5.718235 27.701922 47.278793 71.855538 114.119107 128.071887 193.303564 162.585959 56.864102 24.780383 117.001761 37.201274 177.218214 37.201274 54.888098 0 109.838617-10.320043 162.452929-30.990828C781.098831 884.611369 867.953991 800.8732 915.318742 692.182732 962.683493 583.492264 964.894857 462.865953 921.529187 352.519776z"
                                    fill="#515151"
                                    p-id="9962"
                                  ></path>
                                  <path
                                    d="M424.708911 727.178781c0.008186 0 0.023536 0 0.031722 0 5.711072 0 11.156084-2.984984 14.944364-7.250124l312.328401-351.929328c7.343245-8.257058 6.593162-20.892843-1.655709-28.228925-8.257058-7.351432-20.905122-6.593162-28.233018 1.664919L424.787706 675.975609 308.081681 543.851429c-7.30436-8.280594-19.952424-9.002025-28.233018-1.682315-8.273431 7.312546-9.0624 20.087501-1.749854 28.368094l131.650389 149.34233C413.538501 724.168214 418.983513 727.178781 424.708911 727.178781z"
                                    fill="#515151"
                                    p-id="9963"
                                  ></path>
                                </svg>
                              </template>
                            </el-input>
                          </el-tooltip>
                        </el-col>
                      </el-row>

                      <el-row style="width: 100%; margin-top: 3px" gutter="10">
                        <el-col :span="14">
                          <el-input
                            tabindex="0"
                            @keyup.enter="
                              addCustomRepairItemNew(item, item.AddCustomModel)
                            "
                            v-model="item.AddCustomModel.Name"
                            :placeholder="
                              tools.GetLanguageValue('web.请输入名称')
                            "
                          >
                            <template #prefix>
                              <el-icon>
                                <Tickets />
                              </el-icon>
                            </template>
                          </el-input>

                          <!-- <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="tools.GetLanguageValue('web.名称')"
                        placement="top-start"
                      >
                 
                      </el-tooltip> -->
                        </el-col>
                        <el-col :span="4">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="tools.GetLanguageValue('web.数量')"
                            placement="top-start"
                          >
                            <el-input-number
                              controls-position="right"
                              style="width: calc(100% - 1px)"
                              v-model="item.AddCustomModel.Number"
                              :placeholder="
                                tools.GetLanguageValue('web.请输入数量')
                              "
                              @input="
                                (v) => {
                                  item.AddCustomModel.Number =
                                    v
                                      .replace(/[^\d.]/g, '')
                                      .replace(/^0(\d)/, '$1')
                                      .replace(/^\./, '0.')
                                      .match(/^\d*(\.?\d{0,4})/g)[0] || '';
                                }
                              "
                              @keyup.enter="
                                addCustomRepairItemNew(
                                  item,
                                  item.AddCustomModel
                                )
                              "
                            >
                            </el-input-number>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="4">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="tools.GetLanguageValue('web.单价')"
                            placement="top-start"
                          >
                            <el-input-number
                              controls-position="right"
                              style="width: calc(100% - 1px)"
                              v-model="item.AddCustomModel.Price"
                              :placeholder="
                                tools.GetLanguageValue('web.请输入单价')
                              "
                              @input="
                                (v) => {
                                  // 允许负数和数字，移除非法字符

                                  let cleaned = v.replace(/[^\d.-]/g, '');

                                  // 确保负数符号（-）在数字前面，并且只有一个

                                  cleaned = cleaned
                                    .replace(/(.)-/g, '-$1')
                                    .replace(/-+/g, '-')
                                    .replace(/^(.*?)(-?)(\d+)/, '$2$3$1');

                                  // 移除数字前面的0，除非是数字是小数

                                  cleaned = cleaned
                                    .replace(/^0+(\d)/, '$1')
                                    .replace(/^-0+(\d)/, '-$1');

                                  // 如果字符串以点（.）开头，前面添加0

                                  cleaned = cleaned.replace(/^\./, '0.');

                                  // 保留小数点后最多两位

                                  cleaned =
                                    cleaned.match(/^-?\d*(\.?\d{0,2})/)[0] ||
                                    '';

                                  item.AddCustomModel.Price = cleaned;
                                }
                              "
                              @keyup.enter="
                                addCustomRepairItemNew(
                                  item,
                                  item.AddCustomModel
                                )
                              "
                            >
                            </el-input-number>
                          </el-tooltip>
                        </el-col>

                        <el-col :span="2">
                          <el-button
                            @click="
                              addCustomRepairItemNew(item, item.AddCustomModel)
                            "
                            :icon="Plus"
                            type="primary"
                        /></el-col>
                      </el-row>

                      <el-row style="width: 100%; margin-top: 3px">
                        <el-table
                          @cell-dblclick="editRepairItemData"
                          border
                          :data="item.RepairItems"
                          style="width: 100%"
                        >
                          <!-- height="280" -->
                          <el-table-column
                            class-name="padding0item"
                            prop="Name"
                            :label="tools.GetLanguageValue('web.名字')"
                          >
                            <template #default="scope">
                              <el-input
                                v-if="
                                  scope.row[scope.column.property + 'isShow']
                                "
                                :ref="scope.column.property"
                                v-model="scope.row.Name"
                                @blur="alterData(scope.row, scope.column, item)"
                                @keyup.enter="
                                  alterData(scope.row, scope.column, item)
                                "
                              ></el-input>
                              <span v-else>{{ scope.row.Name }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            class-name="padding0item"
                            prop="Number"
                            :label="tools.GetLanguageValue('web.数量')"
                            width="110"
                          >
                            <template #default="scope">
                              <el-input-number
                                style="width: 90px"
                                controls-position="right"
                                v-if="
                                  scope.row[scope.column.property + 'isShow']
                                "
                                :ref="scope.column.property"
                                v-model="scope.row.Number"
                                @blur="alterData(scope.row, scope.column, item)"
                                @keyup.enter="
                                  alterData(scope.row, scope.column, item)
                                "
                              ></el-input-number>
                              <span v-else>{{ scope.row.Number }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            class-name="padding0item"
                            prop="Price"
                            :label="tools.GetLanguageValue('web.单价')"
                            width="110"
                          >
                            <template #default="scope">
                              <el-input-number
                                style="width: 90px"
                                controls-position="right"
                                v-if="
                                  scope.row[scope.column.property + 'isShow']
                                "
                                :ref="scope.column.property"
                                v-model="scope.row.Price"
                                @blur="alterData(scope.row, scope.column, item)"
                                @keyup.enter="
                                  alterData(scope.row, scope.column, item)
                                "
                              ></el-input-number>
                              <span v-else>{{ scope.row.PriceFormat }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            class-name="padding0item"
                            prop="TotalFormat"
                            :label="tools.GetLanguageValue('web.金额')"
                            width="80px"
                          ></el-table-column>
                          <!-- <el-table-column
                            class-name="padding0item"
                            prop="TaxRate"
                            :label="tools.GetLanguageValue('web.税率')"
                            width="80px"
                          ></el-table-column> -->
                          <el-table-column
                            class-name="padding0item"
                            :label="tools.GetLanguageValue('web.操作')"
                            width="60"
                            fixed="right"
                          >
                            <template #default="{ row }">
                              <el-icon
                                size="22pt"
                                @click="deleteRepairItems(item, row)"
                              >
                                <CircleClose />
                              </el-icon>
                            </template>
                          </el-table-column>
                        </el-table>
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: 60px;
                          "
                        ></div>
                      </el-row>
                    </div>
                  </div>
                </div>
              </el-col>
              <div
                style="
                  display: flex;
                  gap: 5px 5px;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                "
              >
                <div
                  style="
                    font-size: 18pt;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <el-button
                    type="primary"
                    size="small"
                    @click="CreateRepairRework(item)"
                  >
                    {{ tools.GetLanguageValue("web.返修") }}
                  </el-button>
                  <span>.</span>
                  <div style="width: 300px">
                    <span>{{ tools.GetLanguageValue("web.合计金额") }} </span>
                    <span style="color: red; margin-left: 10px">
                      {{ item.TotalPriceFormat }}</span
                    >
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-card>

        <br />
        <br />
        <br />
        <br />
        <br />

        <el-button
          type="danger"
          style="
            width: 250px;
            height: 50px;
            position: fixed;
            bottom: 5px;
            font-size: 15pt;
            right: 340px;
            z-index: 1000000;
          "
          @click="DeleteRepairCompleteOrder"
        >
          {{ tools.GetLanguageValue("web.删除整单") }}
        </el-button>

        <el-button
          :disabled="IsDisabled"
          type="primary"
          style="
            width: 250px;
            height: 50px;
            position: fixed;
            bottom: 5px;
            font-size: 15pt;
            right: 40px;
            z-index: 1000000;
          "
          @click="insertRepairInfo"
        >
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        >
      </el-scrollbar>

      <!-- 添加 -->
      <el-dialog
        v-model="ProductServiceWindow"
        :title="tools.GetLanguageValue('web.新增服务')"
        width="60%"
        :show-close="false"
      >
        <el-tabs v-model="LanguageName">
          <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
            <el-form
              ref="form"
              :model="ProductServerOpt"
              label-width="80px"
              style="margin-top: 10px"
            >
              <el-row>
                <el-col :span="10">
                  <el-form-item :label="tools.GetLanguageValue('web.服务名称')">
                    <el-input
                      v-model="ProductServerOpt.Name"
                      :placeholder="
                        tools.GetLanguageValue('web.请输入服务名称')
                      "
                      @focus="InputSelect"
                    ></el-input>
                  </el-form-item>

                  <el-form-item :label="tools.GetLanguageValue('web.助记码')">
                    <el-input
                      v-model="ProductServerOpt.MnemonicCode"
                      :placeholder="tools.GetLanguageValue('web.请输入助记码')"
                      @focus="InputSelect"
                    ></el-input>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.编号')">
                    <el-input
                      v-model="ProductServerOpt.Code"
                      :placeholder="tools.GetLanguageValue('web.请输入编号')"
                      @focus="InputSelect"
                    ></el-input>
                  </el-form-item>

                  <el-form-item :label="tools.GetLanguageValue('web.单价')">
                    <el-input-number
                      @focus="InputSelect"
                      v-model="ProductServerOpt.Price"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.成本价')">
                    <el-input-number
                      @focus="InputSelect"
                      v-model="ProductServerOpt.Cost"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="4"> </el-col>
                <el-col :span="10">
                  <el-form-item :label="tools.GetLanguageValue('web.原价')">
                    <el-input-number
                      @focus="InputSelect"
                      v-model="ProductServerOpt.OldPrice"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.排序')">
                    <el-input-number
                      @focus="InputSelect"
                      v-model="ProductServerOpt.Sort"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
                    <el-switch
                      v-model="ProductServerOpt.IncludeTax"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    >
                    </el-switch>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.税率')">
                    <el-input-number
                      @focus="InputSelect"
                      v-model="ProductServerOpt.TaxRate"
                      :max="100"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.备注')">
                    <el-input
                      :placeholder="tools.GetLanguageValue('web.请输入备注')"
                      v-model="ProductServerOpt.Description"
                      @focus="InputSelect"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-row>
                <el-col :span="12">
                  <el-form-item label="封面图">
                    <PictureCover
                      v-model="ProductServerOpt.Images"
                      v-model:ImageFormet="ProductServerOpt.ImagesFormet"
                      v-model:ImageModel="ProductServerOpt"
                    />
                  </el-form-item>
                </el-col>
              </el-row> -->
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>

          <el-tab-pane
            :label="item.LanguageName"
            :name="item.LanguageName"
            v-for="(item, index) in ProductServerOpt.Languages"
            :key="index"
          >
            <el-form-item :label="tools.GetLanguageValue('web.名称')">
              <el-input
                v-model="item.Language.Name"
                @focus="InputSelect"
              ></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="ProductServiceWindow = false">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="AddProductService" type="primary">
              {{ tools.GetLanguageValue("web.保存") }}</el-button
            >
          </span>
        </template>
      </el-dialog>

      <!-- 添加 -->
      <el-dialog
        v-model="OpenAttachmentWindow"
        :title="tools.GetLanguageValue('web.编辑配件')"
        width="700px"
        :show-close="false"
      >
        <el-tabs v-model="LanguageName">
          <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
            <el-form
              ref="form"
              :model="AttachmentOpt"
              label-width="130px"
              style="margin-top: 15px"
            >
              <el-form-item :label="tools.GetLanguageValue('web.配件名称')">
                <el-input
                  :placeholder="tools.GetLanguageValue('web.请输入配件名称')"
                  v-model="AttachmentOpt.Name"
                  @focus="InputSelect($event)"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.助记码')">
                <el-input
                  v-model="AttachmentOpt.MnemonicCode"
                  :placeholder="tools.GetLanguageValue('web.请输入助记码')"
                  @focus="InputSelect($event)"
                ></el-input>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.配件价格')">
                <el-input-number
                  @focus="InputSelect($event)"
                  v-model="AttachmentOpt.Price"
                  :min="0"
                  :max="100000"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.配件税率')">
                <el-input-number
                  @focus="InputSelect($event)"
                  v-model="AttachmentOpt.TaxRate"
                  :min="0"
                  :max="100"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
                <el-switch
                  v-model="AttachmentOpt.IncludeTax"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.备注')">
                <el-input
                  :placeholder="tools.GetLanguageValue('web.请输入备注')"
                  v-model="AttachmentOpt.Description"
                  @focus="InputSelect($event)"
                ></el-input>
              </el-form-item>

              <!-- <el-form-item :label="tools.GetLanguageValue('web.封面图')">
        <el-upload
          class="avatar-uploader"
          :action="UploadPictureImageUrl"
          :show-file-list="false"
          :on-success="UploadSuccess"
          :before-upload="UploadSuccess"
          :headers="headersImage"
        >
          <img
            v-if="AttachmentOpt.ImagesFormat"
            :src="AttachmentOpt.ImagesFormat"
            class="avatar"
          />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item> -->
            </el-form>
          </el-tab-pane>
          <el-tab-pane
            :label="item.LanguageName"
            :name="item.LanguageName"
            v-for="(item, index) in AttachmentOpt.Languages"
            :key="index"
          >
            <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
              <el-input
                v-model="item.Language.Name"
                @focus="InputSelect"
              ></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="OpenAttachmentWindow = false">
              {{ tools.GetLanguageValue("web.关闭") }}
            </el-button>
            <el-button type="primary" @click="AddAttachment">
              {{ tools.GetLanguageValue("web.保存") }}</el-button
            >
          </span>
        </template>
      </el-dialog>

      <!-- 新增属性小项 -->
      <el-dialog
        v-model="AttributeGroupItemWindow"
        :title="tools.GetLanguageValue('web.新增属性')"
        width="800px"
        :draggable="true"
        :show-close="false"
      >
        <el-form
          ref="form"
          label-width="120px"
          v-model="AttributeGroupItemModel"
          :model="AttributeGroupItemModel"
          :rules="AttributeGroupRules"
          style="margin-top: 15px"
        >
          <el-form-item
            :label="tools.GetLanguageValue('web.属性组')"
            prop="GroupId"
          >
            <el-select
              v-model="AttributeGroupItemModel.GroupId"
              @change="attrGroupItemChange"
            >
              <el-option
                v-for="item in attributeOptions"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
                placeholder="Please select"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="tools.GetLanguageValue('web.项名称')"
            prop="Name"
          >
            <el-input v-model="AttributeGroupItemModel.Name"></el-input>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.备注')" prop="Desc">
            <el-input v-model="AttributeGroupItemModel.Desc"></el-input>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button
              @click="AttributeGroupItemWindow = !AttributeGroupItemWindow"
            >
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="InsertAttributeGroupItem">
              {{ tools.GetLanguageValue("web.确定") }}</el-button
            >
          </span>
        </template>
      </el-dialog>
      <!-- 选择打印维修单窗口 -->
      <el-dialog
        v-model="UpdatePrintInfoWindow"
        :title="tools.GetLanguageValue('web.选择打印')"
        width="800px"
        :draggable="true"
        :show-close="false"
      >
        <el-checkbox-group v-model="CheckRepairMobileInfoList">
          <el-checkbox
            :label="item.BrandAndModel"
            :value="item"
            v-for="(item, index) in repairForm.RepairMobileInfos"
            :key="index"
          />
        </el-checkbox-group>

        <!-- {{    CheckRepairMobileInfoList }} -->
        <!-- 
        <div
          v-for="(item, index) in repairForm.RepairMobileInfos"
          :key="index"
          style="display: flex; align-items: center; margin-top: 5px"
        >
          <div style="width: 400px">
            {{ item.BrandAndModel }}
          </div>
          <el-button
            size="mini"
            @click="PrintRepairItemWarrant(item)"
            style="margin-right: 15px"
          >
            {{ tools.GetLanguageValue("web.打印保修") }}</el-button
          >
        </div> -->

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="UpdatePrintInfoWindow = !UpdatePrintInfoWindow">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button
              @click="WarrantUpdatePrintInfoBatchClickd"
              type="primary"
            >
              {{ tools.GetLanguageValue("web.打印保修") }}</el-button
            >
          </span>
        </template>
      </el-dialog>

      <!-- 设置窗口 -->
      <el-dialog
        v-model="SettingWindow"
        :title="tools.GetLanguageValue('web.打印设置')"
        width="800px"
        :draggable="true"
        :show-close="false"
      >
        <div style="padding: 8px">
          <el-button size="mini">
            <el-checkbox
              v-model="settingModel.DotPrintDrawer"
              @change="ChangeDotPrintDrawer"
              size="small"
            >
              {{ tools.GetLanguageValue("web.不打印操作人") }}
            </el-checkbox>
          </el-button>

          <el-button size="mini" style="color: #fff">
            <el-checkbox
              v-model="settingModel.DotPrintCounterfoil"
              @change="ChangeDotPrintCounterfoil"
            >
              {{ tools.GetLanguageValue("web.不打印存根") }}
            </el-checkbox>
          </el-button>
        </div>

        <el-form ref="form" label-width="80px">
          <el-form-item :label="tools.GetLanguageValue('web.打印语言')">
            <el-select
              v-model="PrintLanguageId"
              placeholder="请选择"
              @change="ChangePrintLanguage"
            >
              <el-option
                v-for="item in Languages"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="SettingWindow = !SettingWindow">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="InsertSettings">
              {{ tools.GetLanguageValue("web.确定") }}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </navigation>

  <!-- 添加 -->
  <el-dialog
    v-model="SendEmailWindow"
    v-if="SendEmailWindow"
    title="发送消息"
    width="70%"
    :show-close="false"
  >
    <div style="padding: 5px; margin-top: 10px">
      <el-form-item :label="tools.GetLanguageValue('web.标题')">
        <el-input
          :placeholder="tools.GetLanguageValue('web.标题')"
          v-model="SendEmailInfo.Titel"
        ></el-input>
      </el-form-item>

      <QuillEditor
        ref="quillEditorRef"
        v-model:content="SendEmailInfo.Content"
        :content-type="'html'"
        style="height: 300px"
        :toolbar="editToolbar"
        theme="snow"
      >
      </QuillEditor>
    </div>

    <!-- const EmailContent = ref("");
    const EmailTitel = ref(""); -->
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="SendEmailWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button @click="SendEmail" type="primary">
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import jquery from "jquery";
import navigation from "../../../components/Modules/Navigation.vue";
import { ElMessageBox, MessageBox, ElMessage } from "element-plus";

import { ElLoading } from "element-plus";
import Collapse from "../../../components/collapse";
import BoxGridPassword from "../../../components/BoxGridPassword.vue";
import { QuillEditor, Quill } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import {
  ref,
  watch,
  onBeforeMount,
  onUnmounted,
  onMounted,
  reactive,
  nextTick,
} from "vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import {
  Switch,
  Search,
  Plus,
  Pointer,
  ChatLineRound,
  Tickets,
  Hide,
  CircleCloseFilled,
  Coin,
  Minus,
  Calendar,
  Management,
  CirclePlusFilled,
  CircleCheck,
  Goods,
  Close,
  Apple,
  Delete,
  CircleClose,
  Lock,
  Unlock,
  DeleteFilled,
  LocationFilled,
  CirclePlus,
  Cpu,
  FolderAdd,
  Document,
  Warning,
  Refresh,
  Avatar,
  Edit,
  Postcard,
  CreditCard,
  Position,
  Location,
  Star,
  InfoFilled,
  PhoneFilled,
  Message,
} from "@element-plus/icons-vue";

const route = useRoute();
const router = useRouter();

const navigations = ref();
const cardContentHeight = ref(100);

const clientWidth = document.body.clientWidth;
const clientHeight = ref(document.documentElement.clientHeight);
const cardItemHeight = ref(document.documentElement.clientHeight - 120); // 卡片模块高度
const weixiuItemsHeight = ref(document.documentElement.clientHeight - 1);

const CollapseOpt = ref("");
const UserCollapseOpt = ref("");

// 监听页面大小变化事件
const getWindowInfo = () => {
  const windowInfo = {
    width: window.innerWidth,
    hight: window.innerHeight,
  };
  clientHeight.value = document.documentElement.clientHeight;
  navigations.value.onresize();
  cardItemHeight.value = windowInfo.hight - 120;
  weixiuItemsHeight.value =
    clientHeight.value - cardContentHeight.value.clientHeight - 260;
};
window.addEventListener("resize", getWindowInfo);
const LanguageName = ref("first");

const AttributeGroupItemWindow = ref(false);
const AttributeGroupItemModel = ref({});

const OpenAttachmentWindow = ref(false);
const AttachmentOpt = ref({});

const ProductServerOpt = ref({
  Name: "",
  Code: "",
  IncludeTax: "",
  Price: 0,
  OldPrice: 0,
  Sort: 0,
  ProductType: "",
  MnemonicCode: "",
  Cost: "",
});
const ProductServiceWindow = ref(false);

let repairId = ref(""); // 维修单id
let repairItemId = ref(""); // 定位用的维修单行id

// const clientId = ref("")// 客户id
const userListloading = ref(false); // 查询用户loading
const userListoptions = ref(); // 查询用户选项列表
const userSeletvalue = ref(null); // 从查询中选择的用户

const searchInputAttachement = ref("");
const searchInputServerAndQues = ref("");

const totalAllPrice = ref(0); // 统计的价格
const userForm = ref({
  Id: undefined,
}); // 用户数据
const repairForm = ref({
  Id: undefined,
  Repairstatus: ref(1),
}); // 维修单数据

const docTypeOptions = ref([]); // 证件类型
const clientLevelOptions = ref([]); // 客户级别
const repairstatusOptions = ref([]); // 维修状态数据
const passwordTypeOptionsMap = ref([
  {
    value: 0,
    label: "none",
  },
  {
    value: 1,
    label: "password",
  },
  {
    value: 2,
    label: "pattern",
  },
]); // 密码类型定义
const passwordTypeOptions = reactive(passwordTypeOptionsMap.value); // 密码类型
const serverQuestionOptions = ref([]); // 服务和问题数据
const accessoriesOptions = ref([]); // 配件数据
const brandOptions = ref([]); // 品牌数据
const modelOptions = ref([]); // 型号
const attributeOptions = ref([]); // 属性列表

// 定义挂载前
onBeforeMount(() => {});

// 定义onMounted函数
onMounted(() => {
  repairId.value = route.query.id;
  repairItemId.value = route.query.itemId;

  getRepairInfo();
  clientHeight.value = document.documentElement.clientHeight;
  navigations.value.onresize();
  cardItemHeight.value = clientHeight.value - 120;
  weixiuItemsHeight.value =
    clientHeight.value - cardContentHeight.value.clientHeight - 260;
  GetLanguageList();
  GetStoreSettings();
  GetClientDocTypeEnum(); // 客户证件类型
  ClientLevelEnum(); // 客户级别
  getRepairstatusEnum(); // 维修状态选项
  getserverQuestions(); // 服务和问题数据
  getAccessories(); // 配件数据
  getAttributeGroupItems(); // 属性数据
  getProductBrands();

  //监听快捷键
  document.addEventListener("keydown", keyboardDownBack, true);
  PrintLanguageId.value = localStorage.getItem("PrintLanguageId");
  loadPrintSetting();
});

//-------------------发送邮箱---------------------
const fontSizeStyle = Quill.import("attributors/style/size"); // 引入这个后会把样式写在style上
fontSizeStyle.whitelist = [
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "28px",
  "32px",
  "36px",
];
Quill.register(fontSizeStyle, true);
// 设置字体样式
const Font = Quill.import("attributors/style/font"); // 引入这个后会把样式写在style上
const fonts = ["SimSun", "SimHei", "Microsoft-YaHei", "KaiTi", "FangSong"];
Font.whitelist = fonts; // 将字体加入到白名单
Quill.register(Font, true);
const editToolbar = ref([
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  [{ size: ["small", false, "large", "huge"] }][{ font: fonts }], // 字体种类-----[{ font: [] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ direction: "ltl" }], // 文本方向-----[{'direction': 'rtl'}]
  [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
  [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
  ["clean"], // 清除文本格式-----['clean']
  ["link", "image", "video"], // 链接、图片、视频-----['link', 'image', 'video']
]);

const SendEmailWindow = ref(false);
const SendEmailInfo = ref({
  Titel: "",
  Content: "",
  UserId: userForm.value.Id,
});

const quillEditorRef = ref(null);

const OpenSendEmail = () => {
  SendEmailInfo.value.UserId = userForm.value.Id;
 
  SendEmailInfo.value.Content = "";

  if (quillEditorRef.value) {
    quillEditorRef.value.setText("", "silent"); // 或者使用 setContents 方法
  }

  SendEmailWindow.value = true;

  // SendEmail();
};

const SendEmail = () => {
  if (quillEditorRef.value) {
    var Text = quillEditorRef.value.getText(""); // 或者使用 setContents 方法
    console.log(Text.length);
    if (Text.length <= 5) {
      ElMessage({
        message: tools.GetLanguageValue("web.内容过少最少需要10个字符"),
        type: "error",
      });
      return;
    }
  }

  axios.apiMethod(
    "/stores/WorkbenchRepair/SendEmail",
    "post",
    SendEmailInfo.value,
    (result) => {
      if (result.Data.IsSuccess) {
        SendEmailWindow.value = false;
        ElMessage({
          message: result.Data.Msg,
          type: "success",
        });
      } else {
        ElMessage({
          message: result.Data.Msg,
          type: "error",
        });
      }
    }
  );
};

//-----------------------配件拖动----------------

const IsCatAttachmentDraggingMode = ref(
  localStorage.getItem("IsCatAttachmentDraggingMode") == "YES"
);

const CatAttachmentDraggingMode = (item) => {
  IsCatAttachmentDraggingMode.value = item;
  localStorage.setItem(
    "IsCatAttachmentDraggingMode",
    IsCatAttachmentDraggingMode.value ? "YES" : "NO"
  );
};

const IsAttachmentDraggingEdit = ref(false);
// 重置配件排列
const AttachmentDraggingEdit = (item) => {
  IsAttachmentDraggingEdit.value = item;
};

// 重置配件排列
const ResetAttachmentDraggingMode = (item) => {
  accessoriesOptions.value.forEach((a) => {
    a.X = 0;
    a.Y = 0;
  });
};

const draggingAttachmentLook = ref(false);
const thisAttachment = ref(null);

const AttachmentDragStart = (event, server) => {
  if (!IsAttachmentDraggingEdit.value) {
    return;
  }
  draggingAttachmentLook.value = true;
  thisAttachment.value = server;
};

const AttachmentDragEnd = (event, server) => {
  draggingAttachmentLook.value = false;
  thisAttachment.value = null;
};

// 处理正在滑动
const AttachmentDragging = (event) => {
  let server = thisAttachment.value;

  if (true) {
    if (draggingAttachmentLook.value) {
      const div = event.target.closest("div");
      const divRect = div.getBoundingClientRect();

      // 计算鼠标相对于div的位置
      const offsetX = event.clientX - divRect.left;
      const offsetY = event.clientY - divRect.top;

      // 存储这些值以便在拖动时使用
      server.dragOffsetX = offsetX;
      server.dragOffsetY = offsetY;

      server.X = offsetX - 10;
      server.Y = offsetY - 10;
    }
  }
};

var tempAttachment = Number(
  localStorage.getItem("AttachmentDraggingAreaHeigth")
);
const AttachmentDraggingAreaHeigth = ref(
  tempAttachment > 150 ? tempAttachment : 150
);

const AddAttachmentDraggingArea = () => {
  AttachmentDraggingAreaHeigth.value += 30;
  localStorage.setItem(
    "AttachmentDraggingAreaHeigth",
    AttachmentDraggingAreaHeigth.value
  );
};

const MinusAttachmentDraggingArea = () => {
  if (AttachmentDraggingAreaHeigth.value > 60) {
    AttachmentDraggingAreaHeigth.value -= 30;
    localStorage.setItem(
      "AttachmentDraggingAreaHeigth",
      AttachmentDraggingAreaHeigth.value
    );
  }
};

const InsertAttachmentServerPosition = () => {
  axios.apiMethod(
    "/stores/GoodsManage/InsertAttachmentServerPosition",
    "post",
    accessoriesOptions.value,
    (result) => {
      getAccessories();
      IsAttachmentDraggingEdit.value = false;
      ElMessage({
        message: tools.GetLanguageValue("web.操作成功"),
        type: "success",
      });
    }
  );
};

//-----------------------拖动-------------------

const IsDraggingMode = ref(localStorage.getItem("IsDraggingMode") == "YES");

const CatDraggingMode = (item) => {
  IsDraggingMode.value = item;
  localStorage.setItem("IsDraggingMode", IsDraggingMode.value ? "YES" : "NO");
};

// 启用编辑
const IsDraggingEdit = ref(false);
const DraggingEdit = (item) => {
  IsDraggingEdit.value = item;
};

// 重置
const ResetCatDraggingMode = (item) => {
  serverQuestionOptions.value.forEach((a) => {
    a.X = 0;
    a.Y = 0;
  });
};

const draggingLook = ref(false);
const thisServer = ref(null);

const dragStart = (event, server) => {
  if (!IsDraggingEdit.value) {
    return;
  }
  draggingLook.value = true;
  thisServer.value = server;
};

const dragEnd = (event, server) => {
  draggingLook.value = false;
  thisServer.value = null;
};

// 处理正在滑动
const dragging = (event) => {
  let server = thisServer.value;

  if (true) {
    console.log("X=" + event.clientX + ",Y=" + event.clientY);
    if (draggingLook.value) {
      const div = event.target.closest("div");
      const divRect = div.getBoundingClientRect();

      // 计算鼠标相对于div的位置
      const offsetX = event.clientX - divRect.left;
      const offsetY = event.clientY - divRect.top;

      // 存储这些值以便在拖动时使用
      server.dragOffsetX = offsetX;
      server.dragOffsetY = offsetY;

      server.X = offsetX - 10;
      server.Y = offsetY - 10;
    }
  }
};

const InsertServerPosition = () => {
  axios.apiMethod(
    "/stores/GoodsManage/InsertServerPosition",
    "post",
    serverQuestionOptions.value,
    (result) => {
      getserverQuestions();
      IsDraggingEdit.value = false;
      ElMessage({
        message: tools.GetLanguageValue("web.操作成功"),
        type: "success",
      });
    }
  );
};

var temph = Number(localStorage.getItem("DraggingAreaHeigth"));
const DraggingAreaHeigth = ref(temph > 150 ? temph : 150);

const AddDraggingArea = () => {
  DraggingAreaHeigth.value += 30;
  localStorage.setItem("DraggingAreaHeigth", DraggingAreaHeigth.value);
};

const MinusDraggingArea = () => {
  if (DraggingAreaHeigth.value > 60) {
    DraggingAreaHeigth.value -= 30;
    localStorage.setItem("DraggingAreaHeigth", DraggingAreaHeigth.value);
  }
};

//--------------------结束拖动-------------------

const storeSettings = ref({});
// 获取店铺信息
const GetStoreSettings = () => {
  axios.apiMethod("/stores/Setting/GetStoreSettings", "get", {}, (result) => {
    storeSettings.value = result.Data;
  });
};

//切换品牌
const BrandChange = (event, item) => {
  brandOptions.value.forEach((e) => {
    if (e.Id == event) {
      getProductModelsByBrands();

      item.ModelObj = null;
      item.Brand = "00000000-0000-0000-0000-000000000000";
      return;
    }
  });
};

const UserSeletValueEnter = (event, item) => {
  const inputValue = event.target.value;

  if (inputValue != null && inputValue != undefined) {
    if (inputValue.indexOf("/") > -1) {
      var array = inputValue.split("/");

      if (array.length > 0) {
        userForm.value.Name = array[0];
      }

      if (array.length > 1) {
        userForm.value.PhoneNumber = array[1];
      }

      if (array.length > 2) {
        userForm.value.DocNumber = array[2];
      }
      if (array.length > 3) {
        userForm.value.Address = array[3];
      }

      if (array.length > 4) {
        userForm.value.Email = array[4];
      }

      userForm.value.Id = "00000000-0000-0000-0000-000000000000";
      repairForm.value.ClientId = userForm.value.Id;
      selectInput.value = "";
    }
  }
};

//tab 快捷添加品牌
const ModelHandleEvent = (event, item) => {
  const inputValue = event.target.value;

  if (!inputValue) return;

  // 检查是否已存在相同值的条目
  const existingOption = item.ProductModels.find(
    (option) => option.Name.toLowerCase() === inputValue.toLowerCase()
  );
  if (!existingOption) {
    // 不存在则创建新条目
    const newOption = {
      Name: inputValue,
      value: inputValue,
      label: inputValue,
    };
    item.ProductModels.push(newOption);
    item.ModelObj = newOption.value; // 选择新创建的条目
  } else {
    item.ProductModels.forEach((e) => {
      if (e.Name.toLowerCase() === inputValue.toLowerCase()) {
        item.ModelObj = e.Name;
        return;
      }
    });
  }

  event.target.value = "";
};

//触发tab添加临时
const BrandHandleEvent = (event, item) => {
  const inputValue = event.target.value;

  if (!inputValue) return;

  // 检查是否已存在相同值的条目
  const existingOption = brandOptions.value.find(
    (option) => option.Name.toLowerCase() === inputValue.toLowerCase()
  );
  if (!existingOption) {
    // 不存在则创建新条目
    const newOption = {
      Name: inputValue,
      value: inputValue,
      label: inputValue,
    };
    brandOptions.value.push(newOption);
    item.BrandObj = newOption.value; // 选择新创建的条目
  } else {
    brandOptions.value.forEach((e) => {
      if (e.Name.toLowerCase() === inputValue.toLowerCase()) {
        item.BrandObj = e.Name;
        return;
      }
    });
  }

  event.target.value = "";
};

const printAndTermData = ref({}); // 打印和条款数据

// 获取打印设置
const loadPrintSetting = () => {
  axios.apiMethod("/stores/Setting/GetPrintSetting", "get", {}, (result) => {
    printAndTermData.value = result.Data;
    localStorage.setItem("PrintLanguageId", result.Data.PrintLanguageId);
    localStorage.setItem(
      "DotPrintDrawer",
      result.Data.DotPrintDrawer ? "yes" : ""
    );
    localStorage.setItem(
      "DotPrintCounterfoil",
      result.Data.DotPrintCounterfoil ? "yes" : ""
    );

    var printLanguageId = localStorage.getItem("PrintLanguageId");
    tools.GetLanguageByIdToCache(printLanguageId);
  });
};

const SettingWindow = ref(false);
const PrintLanguageId = ref("");

//保存设置
const ChangePrintLanguage = (item) => {
  localStorage.setItem("PrintLanguageId", item);
  PrintLanguageId.value = item;
};

//保存设置
const InsertSettings = (item) => {};

const Languages = ref({});
//语言的列表
const GetLanguageList = (item) => {
  const param = {};
  axios.apiMethod("/all/Language/GetLanguageList", "get", param, (result) => {
    Languages.value = result.Data;
  });
};

//创建返修单
const CreateRepairRework = (item) => {
  ElMessageBox.confirm(
    tools.GetLanguageValue("web.您确定要创建返修单吗"),
    "Warning",
    {
      confirmButtonText: tools.GetLanguageValue("web.确定"),
      cancelButtonText: tools.GetLanguageValue("web.取消"),
      type: "warning",
      title: tools.GetLanguageValue("web.提示"),
    }
  )
    .then(() => {
      axios.apiMethod(
        "/stores/Repair/CreateRepairRework",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            router.push({
              name: "repairDetail",
              query: { id: result.Data.Model, date: new Date().getTime() },
            });

            repairId.value = result.Data.Model;

            getRepairInfo();
          } else {
            ElMessage({
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    })
    .catch(() => {});
};

//删除整单
const DeleteRepairCompleteOrder = () => {
  ElMessageBox.confirm(
    tools.GetLanguageValue("web.您确定要删除整单吗"),
    "Warning",
    {
      confirmButtonText: tools.GetLanguageValue("web.确定"),
      cancelButtonText: tools.GetLanguageValue("web.取消"),
      type: "warning",
      title: tools.GetLanguageValue("web.提示"),
    }
  )
    .then(() => {
      axios.apiMethod(
        "/stores/Repair/DeleteRepairCompleteOrder",
        "get",
        { id: repairForm.value.Id },
        (result) => {
          router.go(-1);
        }
      );
    })
    .catch(() => {});
};

const UpdatePrintInfoWindow = ref(false);
const CheckRepairMobileInfoList = ref([]);
//检查打印的信息
const UpdatePrintInfoExamine = () => {
  if (repairForm.value.RepairMobileInfos.length == 0) {
    return;
  }
  PrintInsertRepairInfo((a) => {
    if (repairForm.value.RepairMobileInfos.length == 1) {
      CheckRepairMobileInfoList.value = [repairForm.value.RepairMobileInfos[0]];

      //如果只有一个，调用这个方法
      PrintRepairItemWarrant(repairForm.value.RepairMobileInfos[0]);
    } else {
      //如果有多个，打开弹出框选择打印项目
      UpdatePrintInfoWindow.value = true;
      CheckRepairMobileInfoList.value = [];
    }
  });
};

//选择打印维修单
const PrintRepairItemWarrant = (item) => {
  if (item.Repairstatus != 600) {
    ElMessageBox.confirm(
      tools.GetLanguageValue("web.您确定要打印保修单吗"),
      "Warning",
      {
        confirmButtonText: tools.GetLanguageValue("web.确定"),
        cancelButtonText: tools.GetLanguageValue("web.取消"),
        type: "warning",
        title: tools.GetLanguageValue("web.提示"),
      }
    )
      .then(() => {
        UpdatePrintInfo(item);
      })
      .catch(() => {});
  } else {
    UpdatePrintInfo(item);
  }
};

//打印的操作
const WarrantUpdatePrintInfoBatchClickd = () => {
  var isTips = false;

  CheckRepairMobileInfoList.value.forEach((item) => {
    if (item.Repairstatus != 600) {
      isTips = true;
    }
  });

  if (!isTips) {
    ConfirmWarrantUpdatePrintInfoBatch();
    return;
  }

  ElMessageBox.confirm(
    tools.GetLanguageValue("web.您确定要打印保修单吗"),
    "Warning",
    {
      confirmButtonText: tools.GetLanguageValue("web.确定"),
      cancelButtonText: tools.GetLanguageValue("web.取消"),
      type: "warning",
      title: tools.GetLanguageValue("web.提示"),
    }
  )
    .then(() => {
      ConfirmWarrantUpdatePrintInfoBatch();
    })
    .catch(() => {});
};

const ConfirmWarrantUpdatePrintInfoBatch = () => {
  axios.apiMethod(
    "/stores/Repair/WarrantUpdatePrintInfoBatch",
    "post",
    CheckRepairMobileInfoList.value,
    (result) => {
      getRepairInfo();
      printWarranty();
    }
  );
};

//打印的操作
const UpdatePrintInfo = (item) => {
  axios.apiMethod(
    "/stores/Repair/WarrantUpdatePrintInfo",
    "post",
    item,
    (result) => {
      getRepairInfo();
      printWarranty(item);
    }
  );
};

//快捷键操作
const keyboardDownBack = (event) => {
  //打印小票
  if (event.altKey && event.key.toUpperCase() == "Q") {
    event.preventDefault(); //阻止浏览器默认行为
    printXiaoPiao();
  }

  //打印小票A4
  if (event.altKey && event.key.toUpperCase() == "W") {
    event.preventDefault(); //阻止浏览器默认行为
    printXiaoPiaoAfour();
  }

  //打印保修
  if (event.altKey && event.key.toUpperCase() == "E") {
    event.preventDefault(); //阻止浏览器默认行为
    printWarranty();
  }

  //新增
  if (event.altKey && event.key.toUpperCase() == "C") {
    event.preventDefault(); //阻止浏览器默认行为
    AddNewMobileInfoData();
  }

  //新增新客户
  if (event.altKey && event.key.toUpperCase() == "D") {
    event.preventDefault(); //阻止浏览器默认行为
    clearUserForm();
  }

  //保存表单
  if (event.altKey && event.key.toUpperCase() == "S") {
    event.preventDefault(); //阻止浏览器默认行为
    insertRepairInfo();
  }
};

const UploadPictureImageUrl = ref(axios.GetUrl() + "/api/Upload/ImageFileWebp");
const headersImage = ref({
  Authorization: localStorage.getItem("Authentication_Header"),
});

const ComImageUrl = ref();
const ComImageFormet = ref();

const UploadSuccess = (response, file, fileList) => {
  if (response.Code == 200) {
    ComImageUrl.value = response.Data.FileName;
    ComImageFormet.value = response.Data.ImageFormet;
    AttachmentOpt.value.ImagesFormat = response.Data.ImageFormet;
    AttachmentOpt.value.Images = response.Data.FileName;
  }
};

onBeforeRouteLeave((to, from, next) => {
  const isEdit = verificationSave();

  if (!isEdit) {
    ElMessageBox.confirm(
      tools.GetLanguageValue("web.您有改变未保存继续退出不会保存您的更改"),
      "Warning",
      {
        confirmButtonText: tools.GetLanguageValue("web.退出"),
        cancelButtonText: tools.GetLanguageValue("web.取消"),
        type: "warning",
        title: tools.GetLanguageValue("web.提示"),
      }
    )
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });

    return;
  }
  next();
});

onUnmounted(() => {
  //移除监听事件
  window.removeEventListener("resize", getWindowInfo);
  //移除快捷键
  document.removeEventListener("keydown", keyboardDownBack, true);
});

const verificationFormJson = ref("");
const verificationUserJson = ref("");

//判断是否保存
const verificationSave = () => {
  if (JSON.stringify(repairForm.value) != verificationFormJson.value) {
    return false;
  }
  if (JSON.stringify(userForm.value) != verificationUserJson.value) {
    return false;
  }
  return true;
};

// 获取品牌
const getProductBrands = () => {
  axios.apiMethod(
    "/stores/Data/GetProductBrands",
    "post",
    {
      RepairId: repairId.value,
    },
    (result) => {
      brandOptions.value = result.Data;
    }
  );
};
var lockTemp = ref(false);
// 合并品牌
const combineProductBrands = () => {
  return;
  try {
    TempBrands.value.forEach((s) => {
      brandOptions.value.unshift(s);
    });

    repairForm.value.RepairMobileInfos.forEach((s) => {
      s.ProductModels.forEach((e) => {
        if (e.IsTemp) {
        }
      });
    });
    verificationFormJson.value = JSON.stringify(repairForm.value);
  } catch (ex) {}
};

//删除临时品牌和临时型号
const DeleteProductBrandAndModels = () => {
  var tempBrands = [];
  brandOptions.value.forEach((e) => {
    if (e.IsTemp) {
      tempBrands.push(e);
    }
  });

  tempBrands.forEach((s) => {
    var index = brandOptions.value.indexOf(s);
    brandOptions.value.splice(index, 1);
  });

  //删除临时型号
};

const TempModels = ref([]);
const TempBrands = ref([]);

// 获取维修单信息
const getRepairInfo = () => {
  axios.apiMethod(
    "/stores/Repair/GetRepairById",
    "get",
    {
      id: repairId.value,
      repairItemId: repairItemId.value,
    },
    (result) => {
      repairForm.value = result.Data;
      userForm.value = result.Data.ClientInfo;
      // TempModels.value = repairForm.value.TempModels;
      // TempBrands.value = repairForm.value.TempBrands;

      if (repairForm.value.RepairMobileInfos.length > 0) {
        CollapseOpt.value = repairForm.value.RepairMobileInfos[0].Id;
      }

      if (result.Data.IsCreate) {
        AddNewMobileInfoData();
        UserCollapseOpt.value = "1";
      }

      if ("00000000-0000-0000-0000-000000000000" != result.Data.ClientId) {
        GetClientById(result.Data.ClientId);
      }

      verificationFormJson.value = JSON.stringify(repairForm.value);

      combineProductBrands();
      getProductModelsByBrands();
    }
  );
};

const GetClientById = (clientId) => {
  axios.apiMethod(
    "/stores/Client/GetClientById",
    "get",
    {
      Id: clientId,
    },
    (result) => {
      userForm.value = result.Data;
      verificationUserJson.value = JSON.stringify(userForm.value);
    }
  );
};

// 根据品牌获取型号
const getProductModelsByBrands = () => {
  repairForm.value.RepairMobileInfos.forEach((s) => {
    lockTemp.value = true;
    axios.apiMethod(
      "/stores/Data/GetProductModelByBrandId",
      "get",
      {
        brandId: s.BrandObj,
        repairId: repairForm.value.Id,
      },
      (result) => {
        s.ProductModels = result.Data;
        verificationFormJson.value = JSON.stringify(repairForm.value);
      }
    );
  });
};

//新增一个
const AddNewMobileInfoData = () => {
  repairForm.value.RepairMobileInfos.unshift({
    RepairItems: [],
    AddCustomModel: {
      Number: 1,
    },
    Id: "00000000-0000-0000-0000-000000000000",
    ActiveTab: "server",
    Repairstatus: 0,
    ProductBrands: brandOptions.value,
    ProductModels: modelOptions.value,
  });

  CollapseOpt.value = "00000000-0000-0000-0000-000000000000";
};

const IsDisabled = ref(false);

// 插入维修单信息
const insertRepairInfo = () => {
  repairForm.value.ClientInfo = userForm.value;
  IsDisabled.value = true;
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });

  axios.apiMethod(
    "/stores/Repair/InsertRepair",
    "post",
    repairForm.value,
    (result) => {
      loading.close();
      IsDisabled.value = false;

      if (result.Data.IsSuccess) {
        ElMessage({
          message: tools.GetLanguageValue("web.操作成功"),
          type: "success",
        });

        getRepairInfo();
        getProductBrands(); //重新加载品牌
      } else {
        ElMessageBox({
          title: tools.GetLanguageValue("web.提示"),
          message: result.Data.Msg,
          type: "error",
        });
      }
    }
  );
};

//给维修的手机添加一个维修服务
const addRepairMobileInfosRepairItem = (mobileInfo, repairItem) => {
  var lock = true;

  //如果是编辑状态，那么不允许添加
  if (IsDraggingEdit.value) {
    ElMessage.closeAll();
    ElMessage({
      message: tools.GetLanguageValue("web.当前是编辑状态，已禁用添加"),
      type: "error",
    });
    return;
  }

  mobileInfo.RepairItems.forEach((element) => {
    if (element.GoodsId == repairItem.GoodsId) {
      element.Number += 1;
      element.Total = element.Number * element.Price;
      element.TotalFormat = parseFloat(element.Total).toFixed(2);
      lock = false;
      mobileInfo.TotalPriceFormat = computeTotalPrice(mobileInfo);
      return;
    }
  });
  if (!lock) {
    return;
  }
  repairItem.Number = 1;
  repairItem.Type = "server";
  repairItem.Total = repairItem.Number * repairItem.Price;
  repairItem.TotalFormat = parseFloat(repairItem.Total).toFixed(2);
  // repairItem.GoodsId = repairItem.
  mobileInfo.RepairItems.unshift(repairItem);
  mobileInfo.TotalPriceFormat = computeTotalPrice(mobileInfo);
};

//给维修的手机添加一个配件
const addRepairMobileInfosFittingsItem = (mobileInfo, fittingsItem) => {
  var lock = true;

  if (IsAttachmentDraggingEdit.value) {
    ElMessage.closeAll();
    ElMessage({
      message: tools.GetLanguageValue("web.当前是编辑状态，已禁用添加"),
      type: "error",
    });

    return;
  }

  mobileInfo.RepairItems.forEach((element) => {
    if (element.GoodsId == fittingsItem.GoodsId) {
      element.Number += 1;
      element.Total = element.Number * element.Price;
      element.TotalFormat = parseFloat(element.Total).toFixed(2);

      lock = false;
      mobileInfo.TotalPriceFormat = computeTotalPrice(mobileInfo);
      return;
    }
  });
  if (!lock) {
    return;
  }
  fittingsItem.Number = 1;
  fittingsItem.Type = "fittings";

  fittingsItem.Total = fittingsItem.Number * fittingsItem.Price;
  fittingsItem.TotalFormat = parseFloat(fittingsItem.Total).toFixed(2);

  // repairItem.GoodsId = repairItem.
  mobileInfo.RepairItems.unshift(fittingsItem);
  mobileInfo.TotalPriceFormat = computeTotalPrice(mobileInfo);

  // row.Total = price * number;
  // row.PriceFormat = price;
  // row.Price = parseFloat(price);
  // row.Number =  parseFloat(number);
  // row.TotalFormat = parseFloat(row.Total).toFixed(2);
};

//给手机添加一个自定义项目
const addCustomRepairItemNew = (mobileInfo, CustomItem) => {
  if (CustomItem.Name == null || CustomItem.Name == "") {
    return;
  }

  var price = parseFloat(CustomItem.Price);
  var number = parseFloat(CustomItem.Number);

  if (isNaN(price)) {
    price = 0;
  }

  if (isNaN(number)) {
    number = 1;
  }

  if (CustomItem.Number > -1000 && CustomItem.Price > -100000) {
    CustomItem.Total = price * CustomItem.Number;

    mobileInfo.RepairItems.unshift({
      Name: CustomItem.Name,
      Number: parseFloat(CustomItem.Number),
      Price: price,
      TaxRate: storeSettings.value.TempMaintainTaxRate,
      Total: parseFloat(CustomItem.Total),
      PriceFormat: price.toFixed(2),
      TotalFormat: parseFloat(CustomItem.Total).toFixed(2),
    });
    mobileInfo.TotalPriceFormat = computeTotalPrice(mobileInfo);

    CustomItem.Name = "";
    CustomItem.Number = "1";
    CustomItem.Price = "";
  }
};

//删除维修项
const deleteRepairItems = (mobileInfo, repairItem) => {
  //去后台删除，如果后台没有，那么前台删除

  var index = mobileInfo.RepairItems.indexOf(repairItem);
  mobileInfo.RepairItems.splice(index, 1);
  mobileInfo.TotalPriceFormat = computeTotalPrice(mobileInfo);
};

// 删除维修的手机信息
const deleteMobileInfoClick = (data) => {
  if (repairForm.value.RepairMobileInfos.length == 1) {
    ElMessageBox({
      title: tools.GetLanguageValue("web.提示"),
      message: tools.GetLanguageValue("web.最少需要保留一个"),
      type: "error",
    });
    return;
  }

  ElMessageBox.confirm(tools.GetLanguageValue("web.确定要删除吗"), "Warning", {
    confirmButtonText: tools.GetLanguageValue("web.确定"),
    cancelButtonText: tools.GetLanguageValue("web.取消"),
    type: "warning",
    title: tools.GetLanguageValue("web.提示"),
  })
    .then(() => {
      axios.apiMethod(
        "/stores/Repair/DeleteRepairMobileAsync",
        "get",
        {
          id: data.Id,
        },
        (result) => {
          for (var i = 0; repairForm.value.RepairMobileInfos.length > 0; i++) {
            if (repairForm.value.RepairMobileInfos[i].Id == data.Id) {
              repairForm.value.RepairMobileInfos.splice(i, 1);
            }
          }
        }
      );
    })
    .catch(() => {});
};

const computeTotalPrice = (item) => {
  var totalPrice = 0;
  item.RepairItems.forEach((e) => {
    // CustomItem.TotalPrice = CustomItem.Price * CustomItem.Number;
    totalPrice += e.Price * e.Number;
  });

  return storeSettings.value.Currency + totalPrice.toFixed(2);
};

//-------------------------------   END  -------------------------------

// 统计总的价格

// 编辑维修项目
const alterData = (row, column, item) => {
  var price = parseFloat(row.Price).toFixed(2);
  var number = parseFloat(row.Number).toFixed(2);

  if (isNaN(price)) {
    price = 0;
  }
  if (isNaN(number)) {
    number = 1;
  }

  row.Total = price * number;
  row.PriceFormat = price;
  row.Price = parseFloat(price);
  row.Number = parseFloat(number);
  row.TotalFormat = parseFloat(row.Total).toFixed(2);

  item.TotalPriceFormat = computeTotalPrice(item);

  row[column.property + "isShow"] = false;
};

// 双击维修项目进行编辑
const editRepairItemData = (row, column) => {
  row[column.property + "isShow"] = true;
  nextTick(() => {
    ref[column.property] && ref[column.property].focus();
  });
};

// 保存用户信息和维修信息
const addClientWithRepairInfo = (op) => {
  if (op === "add") {
    userForm.value.Id = undefined;
  }
  axios.apiMethod(
    "/stores/Repair/AddClientWithRepairInfo",
    "post",
    {
      RepairInfo: repairForm.value,
      ClientInfo: userForm.value,
    },
    (result) => {
      if (result.Data.IsSuccess) {
        console.log("ookk");
        console.log(result);
        // repairForm.value = result.Data.Model.RepairInfo;
        userForm.value = result.Data.Model.ClientInfo;
        repairForm.value.ClientId = userForm.value.Id;
        router.replace("/repairDetail?id=" + repairForm.value.Id);

        ElMessageBox({
          title: "提示",
          message: "操作成功",
        });
      } else {
        ElMessageBox({
          message: result.Data.Msg,
          type: "error",
        });
      }
    }
  );
};

// 快速变更状态
const changeStatus = (row) => {
  for (var i = 0; repairstatusOptions.value.length > i; i++) {
    if (row.Repairstatus == repairstatusOptions.value[i].Key) {
      if (repairstatusOptions.value.length > i + 1) {
        row.Repairstatus = repairstatusOptions.value[i + 1].Key;
        return;
      } else {
        row.Repairstatus = repairstatusOptions.value[0].Key;
        return;
      }
    }
  }
};

const activeTab = "server";

// Guid判断
const isGuid = (str) => {
  const guidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return (
    guidPattern.test(str) && str !== "00000000-0000-0000-0000-000000000000"
  );
};

// 获取客户证件类型
const GetClientDocTypeEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetClientDocTypeEnum",
    "get",
    null,
    (result) => {
      docTypeOptions.value = result.Data;
    }
  );
};
// 获取客户级别
const ClientLevelEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetClientLevelEnum",
    "get",
    null,
    (result) => {
      clientLevelOptions.value = result.Data;
    }
  );
};
// 状态下拉列表初始化
const getRepairstatusEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetRepairstatusEunm",
    "get",
    null,
    (result) => {
      repairstatusOptions.value = result.Data;
    }
  );
};

// 获取服务和问题
const getserverQuestions = () => {
  axios.apiMethod(
    "/stores/Data/GetProductServer",
    "post",
    {
      ScreenValue: searchInputServerAndQues.value,
    },
    (result) => {
      serverQuestionOptions.value = result.Data;
      serverQuestionOptions.value.forEach((a) => {
        if (!(a.TaxRate > 0))
          a.TaxRate = storeSettings.value.TempMaintainTaxRate;
      });
    }
  );
};
// 查询配件
const getAccessories = () => {
  axios.apiMethod(
    "/stores/Data/GetAttachment",
    "post",
    {
      ScreenValue: searchInputAttachement.value,
    },
    (result) => {
      accessoriesOptions.value = result.Data;
    }
  );
}; // 查询品牌

// 获取要选择的属性
const getAttributeGroupItems = () => {
  axios.apiMethod(
    "/stores/Data/GetAttributeGroupOrItemAll",
    "post",
    {},
    (result) => {
      attributeOptions.value = result.Data;
    }
  );
};

const selectInput = ref("");
const selectInputRef = ref(null);

// 监听 value 的变化
watch(selectInput, (newVal, oldVal) => {
  if (newVal.includes("/")) {
    // 如果 value 包含 '/'，则设置焦点到 input 上
    nextTick(() => {
      if (selectInputRef.value) {
        selectInputRef.value.focus();
      }
    });
  }
});

const selectInputChange = (val) => {};

// 远程查询用户
const remoteMethod = (query, cb) => {
  selectInput.value = query;
  if (query) {
    if (query.length < 3) {
      return;
    }

    if (query.indexOf("/") > -1) {
      return;
    }

    userListloading.value = true;
    axios.apiMethod(
      "/stores/Client/GetClientByAnyWords?anyWords=" + query,
      "get",
      {},
      (result) => {
        userListoptions.value = result.Data;
        userListloading.value = false;
      }
    );
  } else {
    userListoptions.value = [];
  }
};
const userSelected = () => {
  // 1选中的用户和当前用户是不是同一个用户
  // 是同一个用户?啥都不用动
  if (userForm.value.Id !== userSeletvalue.value.Id) {
    // 2不是同一个用户--维修单就需要置空，客户Id就要改为当前选中的
    userForm.value = JSON.parse(JSON.stringify(userSeletvalue.value));
    // repairForm.value.Id = undefined // 维修单Id清空
    repairForm.value.ClientId = userForm.value.Id; // 维修单的客户id清空
    // repairItemDataInfo.value = [] // 清空维修的项目
    totalAllPrice.value = 0;
    userSeletvalue.value = "";
  }
};

const AddProductService = () => {
  axios.apiMethod(
    "/stores/GoodsManage/AddProductService",
    "post",
    ProductServerOpt.value,
    (result) => {
      if (result.Data.IsSuccess) {
        getserverQuestions();
        ProductServiceWindow.value = false;
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};
const searchInputServerAndQuesChange = () => {
  getserverQuestions();
};
const searchInputAttachementChange = () => {
  getAccessories();
};

// 打开服务编辑窗口
const OpenProductServiceWindow = (item) => {
  //改-多语言编辑 - 先获取模板

  axios.apiMethod(
    "/stores/GoodsManage/GetProductServerDetail",
    "get",
    {},
    (result) => {
      ProductServerOpt.value = result.Data;
      ProductServiceWindow.value = true;
      LanguageName.value = "first";
    }
  );
};
// 打开配件编辑
const OpenAttachment = (item) => {
  axios.apiMethod(
    "/stores/GoodsManage/GetAttachmentDetail",
    "get",
    {},
    (result) => {
      LanguageName.value = "first";
      AttachmentOpt.value = result.Data;
      OpenAttachmentWindow.value = true;
    }
  );
};
// 添加配件
const AddAttachment = () => {
  axios.apiMethod(
    "/stores/GoodsManage/AddAttachment",
    "post",
    AttachmentOpt.value,
    (result) => {
      if (result.Data.IsSuccess) {
        getAccessories();
        OpenAttachmentWindow.value = false;
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};
// 打开新增属性
const OpenAttributeGroupItemWindow = () => {
  AttributeGroupItemWindow.value = true;
  AttributeGroupItemModel.value = {};
};
// 新增属性时，选择属性组
const attrGroupItemChange = (current) => {};
// 插入新属性
const InsertAttributeGroupItem = () => {
  axios.apiMethod(
    "/stores/GoodsManage/InsertAttributeList",
    "post",
    AttributeGroupItemModel.value,
    (result) => {
      if (result.Code === 200) {
        AttributeGroupItemWindow.value = false;
        getAttributeGroupItems();
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};

//打开打印页面
const OpenMarketReceipt = () => {
  let PrintSettings = GetPrintSettings();

  repairForm.value.LanguageInfo = {
    Malfunction: tools.GetLanguageValue("web.维修问题/服务"),
    Total: tools.GetLanguageValue("web.合计"),
    OrderNumber: tools.GetLanguageValue("web.单号"),
    PhoneNumber: tools.GetLanguageValue("web.手机号码"),
    ClientName: tools.GetLanguageValue("web.姓名"),
    NullMalfunction: tools.GetLanguageValue("web.没有填写"),
  };

  try {
    jquery
      .post(
        "http://" +
          PrintSettings.PrintUrl +
          ":" +
          PrintSettings.PrintPort +
          "/api/PrintService/PrintRepairsTag",
        repairForm.value,
        function (res) {
          console.log(res);
        }
      )
      .fail(function (xhr, status, error) {
        // 请求失败时的操作
        alert(tools.GetLanguageValue("web.请安装打印程序"));
        // 你也可以在这里进行错误处理，例如重试或显示错误信息
      });
  } catch (e) {
    alert(tools.GetLanguageValue("web.请安装打印程序"));
  }
};

//打印预定
const printRepairReserve = () => {
  PrintInsertRepairInfo((a) => {
    // 构造参数字符串
    const queryParams = new URLSearchParams({
      id: repairForm.value.Id,
    });

    // 使用 window.location 打开新页面
    window.open(`/printRepairReserve?${queryParams.toString()}`, "_blank");
  });
};
const printRepairReserveA4 = () => {
  PrintInsertRepairInfo((a) => {
    // 构造参数字符串
    const queryParams = new URLSearchParams({
      id: repairForm.value.Id,
    });

    // 使用 window.location 打开新页面
    window.open(`/printRepairReserveA4?${queryParams.toString()}`, "_blank");
  });
};

// 打印前插入维修单信息
const PrintInsertRepairInfo = (printAction) => {
  repairForm.value.ClientInfo = userForm.value;
  IsDisabled.value = true;
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });

  axios.apiMethod(
    "/stores/Repair/InsertRepair",
    "post",
    repairForm.value,
    (result) => {
      loading.close();
      IsDisabled.value = false;

      if (result.Data.IsSuccess) {
        ElMessage({
          message: tools.GetLanguageValue("web.已自动保存"),
          type: "success",
        });
        getRepairInfo();
        if (printAction != null) {
          printAction();
        }
      } else {
        ElMessageBox({
          title: tools.GetLanguageValue("web.提示"),
          message: result.Data.Msg,
          type: "error",
        });
      }
    }
  );
};

const GetPrintSettings = () => {
  try {
    var PrintSettings = JSON.parse(localStorage.getItem("PrintSettings"));
    if (
      PrintSettings == null ||
      PrintSettings == undefined ||
      PrintSettings == ""
    ) {
      PrintSettings = {
        PrintPort: 0,
        PrintUrl: "",
      };
    }
  } catch (e) {
    PrintSettings = {
      PrintPort: 0,
      PrintUrl: "",
    };
  }
  console.log(PrintSettings);
  return PrintSettings;
};

//打印小票
const printXiaoPiao = () => {
  var PrintSettings = GetPrintSettings();

  if (repairForm.value.Id === undefined) {
    ElMessageBox({
      title: "提示",
      message: "未保存维修单,无法打印",
    });
    return;
  }

  PrintInsertRepairInfo((a) => {
    // 构造参数字符串
    const queryParams = new URLSearchParams({
      id: repairForm.value.Id,
    });

    // 使用 window.location 打开新页面
    window.open(`/printXiaoPiaoEighty?${queryParams.toString()}`, "_blank");
    // router.push({ name: 'printXiaoPiaoEighty', query: { id: 'new' }})
    var s = `/printXiaoPiaoEighty?${queryParams.toString()}`;
  });

  if (PrintSettings.PrintUrl == "" || PrintSettings.PrintPort == 0) {
    return;
  }

  ElMessageBox.confirm(
    tools.GetLanguageValue("web.您需要打印标签吗"),
    "Warning",
    {
      confirmButtonText: tools.GetLanguageValue("web.打印"),
      cancelButtonText: tools.GetLanguageValue("web.取消"),
      type: "warning",
      title: tools.GetLanguageValue("web.提示"),
    }
  )
    .then(() => {
      OpenMarketReceipt();
    })
    .catch(() => {});
};

//打印A4
const printXiaoPiaoAfour = () => {
  if (repairForm.value.Id === undefined) {
    ElMessageBox({
      title: "提示",
      message: "未保存维修单,无法打印",
    });
    return;
  }

  PrintInsertRepairInfo((a) => {
    // 构造参数字符串
    const queryParams = new URLSearchParams({
      id: repairForm.value.Id,
    });

    // 使用 window.location 打开新页面
    window.open(`/printXiaoPiaoAfour?${queryParams.toString()}`, "_blank");
    // router.push({ name: 'printXiaoPiaoAfour', query: { id: 'new' }})
  });
};

//维修单
const printWarranty = () => {
  if (repairForm.value.Id === undefined) {
    ElMessageBox({
      title: "提示",
      message: "未保存维修单,无法打印",
    });
    return;
  }

  var ids = [];
  CheckRepairMobileInfoList.value.forEach((a) => {
    ids.push(a.Id);
  });

  if (ids.length == 0) {
    ElMessageBox({
      title: tools.GetLanguageValue("web.提示"),
      message: tools.GetLanguageValue("web.请选择需要打印的保修项"),
    });
    return;
  }
  UpdatePrintInfoWindow.value = false;
  // 构造参数字符串
  const queryParams = new URLSearchParams({
    id: repairForm.value.Id,
    itemIds: ids,
  });

  // 使用 window.location 打开新页面
  window.open(`/printWarranty?${queryParams.toString()}`, "_blank");
  // router.push({ name: 'printWarranty', query: { id: 'new' }})
};

// 清空用户信息
const clearUserForm = () => {
  userForm.value = {};
  userForm.value.Id = undefined;
  repairForm.value.ClientId = "00000000-0000-0000-0000-000000000000";
};

// 生成guid
const generateGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0;
    var v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
</script>
<style lang="scss">
.el-select {
  .select-trigger {
    .el-select__input {
      min-width: 5px;
    }
  }
}

.floating-save-button {
  position: fixed;
  bottom: 2px;
  right: 20px;
  z-index: 1000;
}

.input {
  margin: 5px 0px;
}

.userInfoInput {
  max-width: 23%;
}

.el-collapse {
  border: none;
  margin-left: -1px;
  /* 调整间距以消除边框影响 */
}

.el-collapse-item__header {
  border: none;
}

.el-collapse-item__header .el-collapse-item__arrow {
  display: none;
  /* 隐藏箭头图标 */
}

.el-collapse-item__wrap {
  border: none;
}

.el-card__body {
  padding: 5px 35px;
}
</style>
<style>
.padding0item {
  padding: 0px !important;
  height: 40px;
}
</style>
